import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormGroupNode, StepFilterFormGroup, StepType, StepsFormArray } from '@selfai-platform/pipeline-common';
import { DestroyService } from '@selfai-platform/shared';
import { MenuItem } from 'primeng/api';
import { Observable, map, startWith, tap } from 'rxjs';
import { AggregatorDictionariesService } from '../../services/aggregator-dictionaries.service';
import { AggregatorFormBuilderService } from '../../services/aggregator-form-builder.service';
import { orderIndentDown, orderIndentUp } from '../../utils';

@Component({
  selector: 'selfai-platform-aggregator-camel-step',
  templateUrl: './aggregator-camel-step.component.html',
  styleUrls: ['./aggregator-camel-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class AggregatorCamelStepComponent implements OnInit {
  readonly stepType = StepType;
  readonly stepTypeItems = Object.values(StepType);
  stepsMenu$!: Observable<MenuItem[]>;

  @Input()
  form!: FormGroup<FormGroupNode>;
  @Input() index!: number;
  @Input() deepLevel = 1;

  get childSteps(): FormGroup<FormGroupNode>[] {
    return (this.childStepFormArray?.controls as FormGroup<FormGroupNode>[]) || [];
  }

  get canHasChidren(): boolean {
    return Boolean(this.childStepFormArray);
  }

  get routeStepsFormArray(): StepsFormArray {
    return this.form.parent as StepsFormArray;
  }

  get type(): StepType | null {
    return this.form.controls.type.value;
  }

  get isUnknownStepType(): boolean {
    return this.stepTypeItems.includes(this.type as StepType) === false;
  }

  private get childStepFormArray(): StepsFormArray | undefined {
    return (this.form.controls as StepFilterFormGroup).steps;
  }

  updateActiveStatus$!: Observable<boolean | null>;

  constructor(
    private readonly aggregatorFormBuilderService: AggregatorFormBuilderService,
    private readonly aggregatorDictionariesService: AggregatorDictionariesService,
  ) {}

  ngOnInit(): void {
    this.stepsMenu$ = this.aggregatorDictionariesService.getDictionary('stepType').pipe(
      map((steps) =>
        steps.map(({ name, value }) => ({
          label: name,
          command: () => this.addChildStep(value),
        })),
      ),
    );

    this.updateActiveStatus$ = this.form.controls.isActive.valueChanges.pipe(
      startWith(this.form.controls.isActive.value),
      tap((isActive) => {
        if (isActive) {
          this.form.enable();
        } else {
          // disable all controls except the isActive
          Object.entries(this.form.controls)
            .filter(([controlName]) => controlName !== 'isActive')
            .forEach(([, control]) => control.disable());
        }
      }),
    );
  }

  removeStep(): void {
    this.aggregatorFormBuilderService.removeStep(this.index, this.routeStepsFormArray);
  }

  addChildStep(stepType: StepType): void {
    if (this.childStepFormArray) {
      this.aggregatorFormBuilderService.addStep(stepType, this.childStepFormArray);
    }
  }

  orderUp(): void {
    orderIndentUp(this.routeStepsFormArray, this.index);
  }

  orderDown(): void {
    orderIndentDown(this.routeStepsFormArray, this.index);
  }
}
