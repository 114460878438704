import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { provideAggregatorApi } from './aggregator-camel/providers';
import { WorkflowModule } from './wokflow/workflow.module';
import { DatasourceTypes } from '@selfai-platform/pipeline-common';
import { KE_AVALIABLE_DATASOURCES } from './common';

export interface PipelineProviderModuleConfig {
  avaliableDatasources: DatasourceTypes[];
}

@NgModule({
  imports: [WorkflowModule.forRoot(), MonacoEditorModule.forRoot()],
})
export class PipelineProviderModule {
  static forRoot(config?: PipelineProviderModuleConfig): ModuleWithProviders<PipelineProviderModule> {
    const providers: Provider[] = [provideAggregatorApi()];

    if (config && config.avaliableDatasources) {
      providers.push({
        provide: KE_AVALIABLE_DATASOURCES,
        useValue: config.avaliableDatasources,
      });
    }

    return {
      ngModule: PipelineProviderModule,
      providers: providers,
    };
  }
}
