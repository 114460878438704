import { Component, ElementRef, EventEmitter, Injector, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import $ from 'jquery';
import pixelWidth from 'string-pixel-width';

import {
  BoardDataSource,
  BoardDataSourceRelation,
  createBoardSource,
  createDatasourceField,
  createQueryParam,
  DatasourceField as Field,
} from '@selfai-platform/bi-domain';
import { isNullOrUndefined } from '@selfai-platform/shared';

import { AbstractPopupComponent } from '../../../common/component/abstract-popup.component';
import { GridComponent } from '../../../common/component/grid/grid.component';
import { header, SlickGridHeader } from '../../../common/component/grid/grid.header';
import { GridOption } from '../../../common/component/grid/grid.option';
import { EventBroadcaster } from '../../../common/event/event.broadcaster';
import { DatasourceService } from '../../../datasource/service/datasource.service';
@Component({
  selector: 'create-board-pop-relation',
  templateUrl: './create-board-pop-relation.component.html',
  styles: ['.ddp-list-selectbox2 li.sys-focus-item { background-color: #f6f6f7 !important; }'],
})
export class CreateBoardPopRelationComponent extends AbstractPopupComponent implements OnInit, OnDestroy {
  @ViewChild('leftGrid')
  private leftGrid: GridComponent;

  @ViewChild('leftSide')
  private _leftSide: ElementRef;

  @ViewChild('rightGrid')
  private rightGrid: GridComponent;

  @ViewChild('rightSide')
  private _rightSide: ElementRef;

  @ViewChild('inputSourceSearchText')
  private _sourceSearchText: ElementRef;

  @ViewChild('inputTargetSearchText')
  private _targetSearchText: ElementRef;

  @ViewChild('sourceFieldCombo')
  private _sourceFieldCombo: ElementRef;

  @ViewChild('targetFieldCombo')
  private _targetFieldCombo: ElementRef;

  private _$sourceFieldCombo;
  private _$targetFieldCombo;
  private _$leftSide;
  private _$rightSide;
  private _$leftFieldList;
  private _$rightFieldList;

  private _queryLimit = 1000;

  private _mode: string;

  @Output('addCancel')
  private _addCancelEvent: EventEmitter<string> = new EventEmitter();

  public relation: BoardDataSourceRelation;

  public sourceSearchText = '';
  public targetSearchText = '';

  public isShowRelationPopup = false;
  public isFromGridMode = true;
  public isToGridMode = true;
  public isShowSrcComboOpts = false;
  public isShowTgtComboOpts = false;

  get filteredSourceList() {
    const srchText: string = this.sourceSearchText.toLowerCase().trim();
    if ('' === srchText) {
      return this.relation.ui.source.uiFields;
    } else {
      return this.relation.ui.source.uiFields.filter((item) => -1 < item.name.toLowerCase().indexOf(srchText));
    }
  }

  get filteredTargetList() {
    const srchText: string = this.targetSearchText.toLowerCase().trim();
    if ('' === srchText) {
      return this.relation.ui.target.uiFields;
    } else {
      return this.relation.ui.target.uiFields.filter((item) => -1 < item.name.toLowerCase().indexOf(srchText));
    }
  }

  constructor(
    protected elementRef: ElementRef,
    protected injector: Injector,
    protected broadCaster: EventBroadcaster,
    private datasourceService: DatasourceService,
  ) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  public addRelation(relation: BoardDataSourceRelation) {
    this._initializeComponent(relation);
    this._mode = 'ADD';
  }

  public editRelation(relation: BoardDataSourceRelation) {
    this._initializeComponent(relation);
    this._mode = 'EDIT';
  }

  public isContainsSearchText(searchText: string, list: any[]): boolean {
    if (list) {
      return list.some((item) => -1 < item.name.toLowerCase().indexOf(searchText.toLowerCase()));
    } else {
      return false;
    }
  }

  public highlightText(sourceText: string, highlightText: string): string {
    if (sourceText) {
      return sourceText.replace(new RegExp('(' + highlightText + ')', 'gi'), '<span class="ddp-txt-search">$1</span>');
    } else {
      return sourceText;
    }
  }

  public isValid(): boolean {
    return !isNullOrUndefined(this.relation.ui.sourceField) && !isNullOrUndefined(this.relation.ui.targetField);
  }

  public completeRelation() {
    if (this.isValid()) {
      if ('ADD' === this._mode) {
        this.broadCaster.broadcast('CREATE_BOARD_CREATE_REL', { relation: this.relation });
      } else if ('EDIT' === this._mode) {
        this.broadCaster.broadcast('CREATE_BOARD_UPDATE_REL', { relation: this.relation });
      }
      this._closeComponent();
    }
  }

  public closePopup() {
    if ('ADD' === this._mode) {
      this._addCancelEvent.emit(this.relation.id);
    }
    this._closeComponent();
  }

  public fromGridHeaderClickHandler(data: { id: string; isSelect: boolean }) {
    const $cols = this._$leftSide.find('.slick-header-column');
    $cols.css('backgroundColor', '');

    if (data.isSelect) {
      const colIdx: number = this.relation.ui.source.uiFields.findIndex((item) => item.name === data.id);
      $cols.eq(colIdx).css('backgroundColor', '#d6d9f1');
      this.relation.ui.sourceField = this.relation.ui.source.uiFields[colIdx];
    } else {
      this.relation.ui.sourceField = null;
    }
  }

  public fromTableClickHandler(columnId: string) {
    const selectedField: Field = this.relation.ui.source.uiFields.find((item) => item.id === columnId);
    if (this.relation.ui.sourceField && this.relation.ui.sourceField.id === selectedField.id) {
      this.relation.ui.sourceField = null;
    } else {
      this.relation.ui.sourceField = selectedField;
    }
  }

  public selectSourceField(field: Field) {
    this.isShowSrcComboOpts = false;
    this.relation.ui.sourceField = field;
    this.leftGrid.columnAllUnSelection();
    this.leftGrid.selectColumn(field.name, true);

    const colIdx: number = this.relation.ui.source.uiFields.findIndex((item) => item.name === field.name);
    if (-1 < colIdx) {
      this.leftGrid.grid.scrollCellIntoView(0, colIdx);
      this._$leftFieldList.scrollTop(colIdx * 30);

      const $cols = this._$leftSide.find('.slick-header-column');
      $cols.css('backgroundColor', '');
      $cols.eq(colIdx).css('backgroundColor', '#d6d9f1');
    }
  }

  public openSearchSourceFields() {
    this.isShowSrcComboOpts = !this.isShowSrcComboOpts;
    this.sourceSearchText = '';
    this.safelyDetectChanges();
    this._sourceSearchText.nativeElement.focus();
  }

  public sourceComboKeyEvent(event: KeyboardEvent) {
    const $currFocusItem = this._$sourceFieldCombo.find('li.sys-focus-item');

    switch (event.keyCode) {
      case 38: {
        let $prev;
        if (0 === $currFocusItem.length) {
          $prev = this._$sourceFieldCombo.find('li:last');
        } else {
          $prev = $currFocusItem.prev('li');
          0 === $prev.length && ($prev = this._$sourceFieldCombo.find('li:last'));
        }

        $prev.addClass('sys-focus-item');
        $currFocusItem.removeClass('sys-focus-item');
        this._$sourceFieldCombo.scrollTop($prev.index() * 26);
        break;
      }
      case 40: {
        let $next;
        if (0 === $currFocusItem.length) {
          $next = this._$sourceFieldCombo.find('li:first');
        } else {
          $next = $currFocusItem.next('li');
          0 === $next.length && ($next = this._$sourceFieldCombo.find('li:first'));
        }

        $next.addClass('sys-focus-item');
        $currFocusItem.removeClass('sys-focus-item');
        this._$sourceFieldCombo.scrollTop($next.index() * 26);
        break;
      }
      case 13:
        $currFocusItem.trigger('click');
        $currFocusItem.removeClass('sys-focus-item');
        break;
    }
  }

  public hoverSourceItem(event: MouseEvent) {
    const $target = $(event.currentTarget);
    $target.parent().find('.sys-focus-item').removeClass('sys-focus-item');
    $target.addClass('sys-focus-item');
  }

  public changeViewModeSource(isGridMode: boolean) {
    this.isFromGridMode = isGridMode;
    this.safelyDetectChanges();
    if (this.relation.ui.sourceField) {
      this.selectSourceField(this.relation.ui.sourceField);
    }
  }

  public toGridHeaderClickHandler(data: { id: string; isSelect: boolean }) {
    const $cols = this._$rightSide.find('.slick-header-column');
    $cols.css('backgroundColor', '');

    if (data.isSelect) {
      const colIdx: number = this.relation.ui.target.uiFields.findIndex((item) => item.name === data.id);
      $cols.eq(colIdx).css('backgroundColor', '#d6d9f1');
      this.relation.ui.targetField = this.relation.ui.target.uiFields[colIdx];
    } else {
      this.relation.ui.targetField = null;
    }
  }

  public toTableClickHandler(columnId: string) {
    const selectedField: Field = this.relation.ui.target.uiFields.find((item) => item.id === columnId);
    if (this.relation.ui.targetField && this.relation.ui.targetField.id === selectedField.id) {
      this.relation.ui.targetField = null;
    } else {
      this.relation.ui.targetField = selectedField;
    }
  }

  public selectTargetField(field: Field) {
    this.isShowTgtComboOpts = false;
    this.relation.ui.targetField = field;
    this.rightGrid.columnAllUnSelection();
    this.rightGrid.selectColumn(field.name, true);

    const colIdx: number = this.relation.ui.target.uiFields.findIndex((item) => item.name === field.name);
    if (-1 < colIdx) {
      this.rightGrid.grid.scrollCellIntoView(0, colIdx);
      this._$rightFieldList.scrollTop(colIdx * 30);

      const $cols = this._$rightSide.find('.slick-header-column');
      $cols.css('backgroundColor', '');
      $cols.eq(colIdx).css('backgroundColor', '#d6d9f1');
    }
  }

  public openSearchTargetFields() {
    this.isShowTgtComboOpts = !this.isShowTgtComboOpts;
    this.targetSearchText = '';
    this.safelyDetectChanges();
    this._targetSearchText.nativeElement.focus();
  }

  public targetComboKeyEvent(event: KeyboardEvent) {
    const $currFocusItem = this._$targetFieldCombo.find('li.sys-focus-item');

    switch (event.keyCode) {
      case 38: {
        let $prev;
        if (0 === $currFocusItem.length) {
          $prev = this._$targetFieldCombo.find('li:last');
        } else {
          $prev = $currFocusItem.prev('li');
          0 === $prev.length && ($prev = this._$targetFieldCombo.find('li:last'));
        }

        $prev.addClass('sys-focus-item');
        $currFocusItem.removeClass('sys-focus-item');
        this._$targetFieldCombo.scrollTop($prev.index() * 26);
        break;
      }
      case 40: {
        let $next;
        if (0 === $currFocusItem.length) {
          $next = this._$targetFieldCombo.find('li:first');
        } else {
          $next = $currFocusItem.next('li');
          0 === $next.length && ($next = this._$targetFieldCombo.find('li:first'));
        }

        $next.addClass('sys-focus-item');
        $currFocusItem.removeClass('sys-focus-item');
        this._$targetFieldCombo.scrollTop($next.index() * 26);
        break;
      }
      case 13:
        $currFocusItem.trigger('click');
        $currFocusItem.removeClass('sys-focus-item');
        break;
    }
  }

  public hoverTargetItem(event: MouseEvent) {
    const $target = $(event.currentTarget);
    $target.parent().find('.sys-focus-item').removeClass('sys-focus-item');
    $target.addClass('sys-focus-item');
  }

  public changeViewModeTarget(isGridMode: boolean) {
    this.isToGridMode = isGridMode;
    this.safelyDetectChanges();
    if (this.relation.ui.targetField) {
      this.selectTargetField(this.relation.ui.targetField);
    }
  }

  private _closeComponent() {
    this.isShowRelationPopup = false;
    this.relation = null;
  }

  private _initializeComponent(relation: BoardDataSourceRelation) {
    this.relation = relation;
    this._queryLimit = 100;

    const sourceDataSource: BoardDataSource = this.relation.ui.source;
    const targetDataSource: BoardDataSource = this.relation.ui.target;

    this._queryData(sourceDataSource.engineName, sourceDataSource.temporary)
      .then((data) => {
        const grid: GridComponent = this.updateGrid(data[0], sourceDataSource.uiFields, 'left');
        if (relation.ui.sourceField) {
          grid.selectColumn(relation.ui.sourceField.name, true);
        }
        this.changeDetect.detectChanges();
      })
      .catch((err) => this.commonExceptionHandler(err));

    this._queryData(targetDataSource.engineName, targetDataSource.temporary)
      .then((data) => {
        const grid: GridComponent = this.updateGrid(data[0], targetDataSource.uiFields, 'right');
        if (relation.ui.targetField) {
          grid.selectColumn(relation.ui.targetField.name, true);
        }
        this.changeDetect.detectChanges();
      })
      .catch((err) => this.commonExceptionHandler(err));

    this.isShowRelationPopup = true;

    this.safelyDetectChanges();

    this._$sourceFieldCombo = $(this._sourceFieldCombo.nativeElement);
    this._$targetFieldCombo = $(this._targetFieldCombo.nativeElement);

    this._$leftSide = $(this._leftSide.nativeElement);
    this._$rightSide = $(this._rightSide.nativeElement);
    this._$leftFieldList = this._$leftSide.find('.ddp-wrap-scroll');
    this._$rightFieldList = this._$rightSide.find('.ddp-wrap-scroll');
  }

  private _queryData(dsName: string, isTemporary: boolean = false, loading: boolean = true): Promise<[any, Field[]]> {
    return new Promise<any>((res, rej) => {
      const params = createQueryParam();
      params.limits.limit = this._queryLimit;

      params.dataSource = createBoardSource();
      params.dataSource.type = 'default';
      params.dataSource.name = dsName;
      params.dataSource.temporary = isTemporary;

      loading && this.loadingShow();
      this.datasourceService
        .getDatasourceQuery(params)
        .then((data) => {
          let fieldList: Field[] = [];
          if (data && 0 < data.length) {
            fieldList = Object.keys(data[0]).map((keyItem) => {
              const tempInfo = createDatasourceField({
                name: keyItem,
              });

              return tempInfo;
            });
          }
          res([data, fieldList]);
          this.loadingHide();
        })
        .catch((err) => {
          rej(err);
          this.loadingHide();
        });
    });
  }

  private updateGrid(data: any, fields: Field[], targetGrid: string = 'main'): GridComponent {
    const headers: header[] = fields.map((field: Field) => {
      const headerWidth: number = Math.floor(pixelWidth(field.name, { size: 12 })) + 62;
      return new SlickGridHeader()
        .Id(field.name)
        .Name(field.name)
        .Field(field.name)
        .Behavior('select')
        .Selectable(false)
        .CssClass('cell-selection')
        .Width(headerWidth)
        .CannotTriggerInsert(true)
        .Resizable(true)
        .Unselectable(false)
        .Sortable(false)
        .Formatter((row, cell, value, columnDef) => {
          if (columnDef.select) {
            return (
              "<div style='background-color:#d6d9f1; position:absolute; top:0; left:0; right:0; bottom:0; line-height:30px; padding:0 10px;'>" +
              (value ? value : '&nbsp;') +
              '</div>'
            );
          } else {
            return value;
          }
        })
        .build();
    });

    let rows: any[] = data;

    if (data.length > 0 && !data[0].hasOwnProperty('id')) {
      rows = rows.map((row: any, idx: number) => {
        row.id = idx;
        return row;
      });
    }

    let grid: GridComponent;

    if (targetGrid === 'left') {
      grid = this.leftGrid;
    } else if (targetGrid === 'right') {
      grid = this.rightGrid;
    }

    grid.destroy();

    if (0 < headers.length) {
      grid.create(
        headers,
        rows,
        new GridOption()
          .EnableHeaderClick(true)
          .MultiSelect(false)
          .SyncColumnCellResize(true)
          .MultiColumnSort(true)
          .RowHeight(32)
          .build(),
      );

      if (0 === rows.length) {
        grid.invalidateAllRows();
        grid.elementRef.nativeElement.querySelector('.grid-canvas').innerHTML =
          '<div class="ddp-data-empty"><span class="ddp-data-contents">' +
          this.translateService.instant('msg.space.ui.no.data') +
          '</span></div>';
      }
    }

    return grid;
  }
}
