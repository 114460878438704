import { Injectable, Pipe } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { IdentityProviderAbstractService } from '../identity-provider-abstract.service';
import { from, map } from 'rxjs';
import { ProviderUserProfile } from '../provider-user-profile';
import { BiPermission, PipelinePermission } from '../../permission';
import { getRolesForNoneProvider } from './get-roles-for-none-provider';

@Injectable({
  providedIn: 'root',
})
export class NoneIdentityProviderService extends IdentityProviderAbstractService {
  private fakeProfile: ProviderUserProfile = {
    id: 'User',
    username: 'User User',
    email: 'user@example.com',
  };

  private _authorizationHeaderName = 'Authorization';
  private _bearerPrefix = 'Bearer';

  constructor() {
    super();
  }

  init() {
    return Promise.resolve(true);
  }

  getUserInfo() {
    return;
  }

  shouldAddToken = () => true;
  shouldUpdateToken = () => true;

  register(): Promise<void> {
    return Promise.resolve();
  }

  isUserInRole(): boolean {
    return true;
  }

  getUserRoles(): string[] {
    const roles = getRolesForNoneProvider()

    return [...roles.keRoles, ...roles.kdRoles];
  }

  isTokenExpired(): boolean {
    return false;
  }

  getUsername(): string {
    return this.fakeProfile.username;
  }

  clearToken(): void {
    return;
  }

  getToken() {
    return Promise.resolve('');
  }

  updateToken() {
    return Promise.resolve(true);
  }

  login() {
    return Promise.resolve();
  }

  logout(redirectUri?: string) {
    return Promise.resolve();
  }

  addTokenToHeader(headers?: HttpHeaders) {
    return from(this.getToken()).pipe(
      map((token) => (token ? headers.set(this._authorizationHeaderName, this._bearerPrefix + token) : headers)),
    );
  }

  loadUserProfile() {
    return Promise.resolve({});
  }

  isLoggedIn() {
    return Promise.resolve(true);
  }
}
