<div class="menu-wrapper">
  <div class="layout-menu-container" [ngClass]="menuMode$ | async">
    <ul class="layout-menu">
      <li
        #menuItem
        *ngFor="let root of menu"
        class="layout-root-menuitem"
        [ngClass]="{ 'active-menuitem': menuItem === (activeMenuItem$ | async) }"
      >
        <div (click)="toogleMenu(root)">
          <a
            *ngIf="root.routerLink; else externalLinkMain"
            class="layout-menuitem-text no-hover"
            routerLinkActive="active-root-item"
            [routerLink]="root.routerLink"
            [attr.target]="root.target"
            [attr.tabindex]="0"
            (mouseenter)="onMouseEnter(menuItem)"
            pRipple
          >
            {{ root.label ? (root.label | translate) : '' }}
          </a>
          <ng-template #externalLinkMain>
            <a
              *ngIf="root.url"
              class="external-link layout-menuitem-text no-hover"
              [attr.href]="root.url"
              [attr.target]="root.target"
              [attr.tabindex]="0"
              pRipple
            >
              {{ root.label ? (root.label | translate) : '' }}
            </a>
          </ng-template>
          <span *ngIf="!root.routerLink && !root.url" class="layout-menuitem-text">
            {{ root.label ? (root.label | translate) : '' }}
          </span>
          <i
            class="pi pi-fw layout-submenu-toggler"
            [ngClass]="root.expanded ? 'pi-angle-down' : 'pi-angle-right'"
            *ngIf="root.items && root.items.length"
          ></i>
        </div>
        <a
          *ngIf="root.routerLink || root.items?.length; else externalLinkMainHorizontal"
          [routerLink]="root.routerLink"
          [attr.target]="root.target"
          [attr.tabindex]="0"
          (mouseenter)="onMouseEnter(menuItem)"
          pRipple
        >
          <span class="layout-menuitem-text">{{ root.label ? (root.label | translate) : '' }}</span>
          <i class="pi pi-fw pi-angle-down layout-submenu-toggler" *ngIf="root.items && root.items.length"></i>
        </a>
        <ng-template #externalLinkMainHorizontal>
          <a
            *ngIf="root.url"
            class="external-link"
            [attr.href]="root.url"
            [attr.target]="root.target"
            [attr.tabindex]="0"
            pRipple
          >
            {{ root.label ? (root.label | translate) : '' }}
          </a>
        </ng-template>
        <ng-container *ngIf="root.items && root.items.length">
          <ul [ngClass]="!root.expanded && menuMode === 'vertical' ? 'hidden' : ''">
            <li *ngFor="let item of root.items" routerLinkActive="active-menuitem">
              <a
                *ngIf="item.routerLink; else externalLink"
                [routerLink]="item.routerLink"
                (click)="item.command?.({ originalEvent: $event, item: item })"
                routerLinkActive="active-menuitem-routerlink"
                [attr.target]="item.target"
                [attr.tabindex]="0"
                pRipple
              >
                <i [ngClass]="item.icon" class="layout-menuitem-icon"></i>
                <span class="layout-menuitem-text">{{ item.label ? (item.label | translate) : '' }}</span>
              </a>
              <ng-template #externalLink>
                <a
                  *ngIf="item.url"
                  [attr.href]="item.url"
                  (click)="item.command?.({ originalEvent: $event, item: item })"
                  [attr.target]="item.target"
                  [attr.tabindex]="0"
                  pRipple
                >
                  <i [ngClass]="item.icon" class="layout-menuitem-icon"></i>
                  <span class="layout-menuitem-text">{{ item.label ? (item.label | translate) : '' }}</span>
                </a>
              </ng-template>
            </li>
          </ul>
        </ng-container>
      </li>
    </ul>

    <selfai-platform-recycle-menu></selfai-platform-recycle-menu>
  </div>
</div>
