import {
  DivisionZeroStrategy,
  Expression,
  ExpressionItem,
  ExpressionLegacy,
  ExpressionSource,
  ExpressionSourceLegacy,
  ExpressionSourceModel,
  ExpressionType,
  ExpressionTypeLegacy,
  GraphNode,
  mapExpressionLegacy,
  mapExpressionSourceTypeLegacy,
  MultiplierParameter,
  Parameter,
  SelectionColumnItem,
  SelectorParameter,
  SingleChoiceParameter,
  WorkflowExpressionData,
  WorkflowExpressionItemData,
} from '@selfai-platform/pipeline-common';
import { v4 as uuidv4 } from 'uuid';

export function normalizeData(workflowData: WorkflowExpressionData): ExpressionItem[] {
  return workflowData.Expressions.map(normalizeItem);
}

function normalizeItem(workflowData: WorkflowExpressionItemData): ExpressionItem {
  return {
    id: uuidv4(),
    resultColumn: workflowData['Result column'] || '',
    type: normalizeType(workflowData['Type']),
  };
}

function normalizeType(workflowData: ExpressionTypeLegacy | null): ExpressionType {
  if (!workflowData) {
    return { kind: null };
  }

  const legacyExpressionKind = Object.keys(workflowData)[0] as keyof typeof mapExpressionLegacy;
  const kind = mapExpressionLegacy[legacyExpressionKind] as Expression;
  const dataExpression = workflowData[legacyExpressionKind];

  if (!dataExpression) {
    return { kind };
  }

  return {
    kind,
    sourceValue1: dataExpression['Source value #1'] && normalizeSource(dataExpression['Source value #1']),
    sourceValue2: dataExpression['Source value #2'] && normalizeSource(dataExpression['Source value #2']),
    divisionZeroStrategy:
      dataExpression['Division by zero strategy'] &&
      (Object.keys(dataExpression['Division by zero strategy'])[0] as DivisionZeroStrategy),
    sqlExpression: dataExpression['input expression'],
  };
}

function normalizeSource(sourceLegacy: ExpressionSourceLegacy): ExpressionSourceModel {
  const typeSourceLegacy = Object.keys(sourceLegacy)[0] as keyof typeof mapExpressionSourceTypeLegacy;

  if (!typeSourceLegacy) {
    return {
      type: null,
      value: null,
    };
  }

  const type: ExpressionSource = mapExpressionSourceTypeLegacy[typeSourceLegacy];

  if (type === ExpressionSource.SELECT_EXISTING_COLUMN) {
    const lefacyValue = sourceLegacy['Select existing column']?.Column?.value;

    return {
      type,
      value: lefacyValue || null,
    };
  }

  const lefacyValue = sourceLegacy[typeSourceLegacy];

  return {
    type,
    value: lefacyValue ? Object.values(lefacyValue)[0] : null,
  };
}

export function normalizeToLegacyData(items: ExpressionItem[]): WorkflowExpressionData {
  return {
    Expressions: items.map(normalizeToLegacyItem),
  };
}

function normalizeToLegacyItem(item: Omit<ExpressionItem, 'id'>): WorkflowExpressionItemData {
  const typeKeyLegacy: keyof ExpressionTypeLegacy = Object.entries(mapExpressionLegacy).find(
    ([, value]) => value === item.type.kind,
  )?.[0] as keyof ExpressionTypeLegacy;

  return {
    'Result column': item.resultColumn,
    Type: {
      [typeKeyLegacy]: mapExpressionDataToLegacy(item.type),
    },
  };
}

function mapExpressionDataToLegacy(type: ExpressionType): ExpressionLegacy {
  if (type.kind === Expression.EXPRESSION) {
    return {
      'input expression': type.sqlExpression,
    };
  }

  const sources: Pick<ExpressionLegacy, 'Source value #1' | 'Source value #2'> = {
    'Source value #1': mapSourceToLegacy(type.sourceValue1),
    'Source value #2': mapSourceToLegacy(type.sourceValue2),
  };

  if (type.kind === Expression.DIVISION) {
    const divisionZeroStrategy: DivisionZeroStrategy = type.divisionZeroStrategy || DivisionZeroStrategy.Zero;

    return {
      ...sources,
      'Division by zero strategy': {
        [divisionZeroStrategy]: {},
      },
    };
  }

  return sources;
}

function mapSourceToLegacy(source?: ExpressionSourceModel | null): ExpressionSourceLegacy | null {
  if (!source) {
    return null;
  }

  switch (source.type) {
    case ExpressionSource.SELECT_EXISTING_COLUMN: {
      return {
        'Select existing column': {
          Column: {
            type: 'column',
            value: source.value as string,
          },
        },
      };
    }
    case ExpressionSource.INPUT_AMOUNT: {
      return {
        'Input amount': {
          Amount: source.value as number,
        },
      };
    }
    case ExpressionSource.INPUT_COLUMN_NAME: {
      return {
        'Input column name': {
          'Column name': source.value as string,
        },
      };
    }
    default: {
      return null;
    }
  }
}

export function extractSelectionItems(
  parameters: GraphNode<WorkflowExpressionData>['parameters'],
): SelectionColumnItem[] | undefined {
  if (!parameters) {
    return undefined;
  }

  const multiplierParameter: MultiplierParameter | undefined = parameters.parameters?.find((parameter) =>
    isExpressionParameter(parameter),
  ) as MultiplierParameter;

  const singleChoiceParameter: SingleChoiceParameter = multiplierParameter?.parametersLists?.[0]?.parameters.find(
    (parameter) => isTypeParameter(parameter),
  ) as SingleChoiceParameter;

  const additionParameter: SingleChoiceParameter = singleChoiceParameter?.possibleChoicesList['Addition (+)']
    ?.parameters?.[0] as SingleChoiceParameter;

  const selectExisitngColumn: SelectorParameter = additionParameter?.possibleChoicesList['Select existing column']
    ?.parameters?.[0] as SelectorParameter;

  return selectExisitngColumn?.dataFrameSchema?.fields.map(({ name }, index) => ({ name, index }));
}

function isExpressionParameter(parameter: Parameter): parameter is MultiplierParameter {
  return (
    parameter.name === 'Expressions' &&
    Object.prototype.hasOwnProperty.call(parameter as MultiplierParameter, 'parametersLists')
  );
}

function isTypeParameter(parameter: Parameter): parameter is SingleChoiceParameter {
  return (
    parameter.name === 'Type' &&
    Object.prototype.hasOwnProperty.call(parameter as SingleChoiceParameter, 'possibleChoicesList')
  );
}
