import {
  BookModel,
  DashboardListApiModel,
  DashboardListQueryParams,
  WorkspaceDetailViewModel,
} from '@selfai-platform/bi-api';
import { PageParams } from '@selfai-platform/shared';
import { omit } from 'lodash';
import { DashboardList, normalizeDashboardApiModelToDashboard } from '../../dashboard';
import { WorkbookList, WorkbookListItem } from '../models';

export function workbookListApiNormalizer(res: WorkspaceDetailViewModel): WorkbookList {
  return {
    workspace: omit(res, ['workBooks', 'booksContour']),
    workbooks: res.workBooks.map((book) => workbookApiNormalizer(book)),
    page: {
      number: Math.round(res.booksContour.offset / res.booksContour.limit) + 1,
      size: res.booksContour.limit,
      totalElements: res.countByBookType.workBook,
      totalPages: Math.ceil(res.countByBookType.workBook / res.booksContour.limit),
    },
  };
}

export function workbookApiNormalizer(book: BookModel): WorkbookListItem {
  return {
    ...book,
    author: book.createdBy.fullName || book.createdBy.username,
    create: book.createdTime,
    modified: book.modifiedTime,
    favorite: book.favorite,
    dashBoards: book.dashBoards?.map(normalizeDashboardApiModelToDashboard),
    notRemovedDashboards: book.notRemovedDashboards?.map(normalizeDashboardApiModelToDashboard),
  };
}

export function normalizeDashboardListApiModelToDashboardList(dashboardSource: DashboardListApiModel): DashboardList {
  return {
    dashboards:
      dashboardSource._embedded?.dashboards.map(normalizeDashboardApiModelToDashboard).map((dashboard) => ({
        ...dashboard,
        create: dashboard.createdTime,
        modified: dashboard.modifiedTime,
        author: dashboard.createdBy.fullName || dashboard.createdBy.username,
      })) || [],
    page: dashboardSource.page,
  };
}

export function normalizePageParamsToQuery(pageParams: PageParams): DashboardListQueryParams {
  return {
    page: pageParams.pageNumber - 1,
    size: pageParams.pageSize,
    containsText: pageParams.query?.trim(),
    sort: pageParams.sortField && `${pageParams.sortField},${pageParams.sortOrder}`,
    projection: 'forListView',
  };
}
