import { ModuleWithProviders, NgModule } from '@angular/core';
import { SessionManagerApiService } from './services/session-manager-api.service';
import { SessionsManagerAdapter } from './services/sessions-manager.adapter';

@NgModule()
export class WorkflowModule {
  static forRoot(): ModuleWithProviders<WorkflowModule> {
    return {
      ngModule: WorkflowModule,
      providers: [{ provide: SessionsManagerAdapter, useClass: SessionManagerApiService }],
    };
  }
}
