import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { Dashboard, UIOption } from '@selfai-platform/bi-domain';

import { CommonOptionComponent } from '../../chart-style/common-option.component';
import { DataLabelOptionComponent } from '../../chart-style/datalabel-option.component';
import { FormatOptionComponent } from '../../chart-style/format-option.component';
import { MapFormatOptionComponent } from '../../chart-style/map/map-format-option.component';
import { MapLayerOptionComponent } from '../../chart-style/map/map-layer-option.component';
import { MapTooltipOptionComponent } from '../../chart-style/map/map-tooltip-option.component';
import { SecondaryIndicatorComponent } from '../../chart-style/secondary-indicator.component';
import { PageFilterPanelComponent } from '../../filter/filter-panel.component';

@Component({
  selector: 'selfai-platform-page-section-side',
  templateUrl: './page-section-side.component.html',
  styleUrls: ['./page-section-side.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageSectionSideComponent {
  @ViewChild('formatOption')
  formatOption: FormatOptionComponent;

  @ViewChild('commonOption')
  commonOption: CommonOptionComponent;

  @ViewChild('dataLabelOption')
  dataLabelOption: DataLabelOptionComponent;

  @ViewChild('secondaryIndicatorOption')
  secondaryIndicatorOption: SecondaryIndicatorComponent;

  @ViewChild('mapFormatOption')
  mapFormatOption: MapFormatOptionComponent;

  @ViewChild('mapTooltipOption')
  mapTooltipOption: MapTooltipOptionComponent;

  @ViewChild('mapLayerOption')
  mapLayerOption: MapLayerOptionComponent;

  @ViewChild(PageFilterPanelComponent)
  _filterPanelComp: PageFilterPanelComponent;

  @Input() rnbMenu: any;
  @Input() selectChart: any;
  @Input() possibleChartCheck: any;
  @Input() isChartShow: any;
  @Input() uiOption: any;
  @Input() pivot: any;
  @Input() shelf: any;
  @Input() widget: any;
  @Input() boardFilters: any;
  @Input() widgetConfiguration: any;
  @Input() resultData: any;
  @Input() dashboard: Dashboard;

  @Output() onSetDrawChartParam = new EventEmitter<any>();
  @Output() changeAxisByStack = new EventEmitter<any>();
  @Output() onShowPopup = new EventEmitter<any>();
  @Output() onFormatCommonChange = new EventEmitter<any>();
  @Output() onFormatEachChange = new EventEmitter<any>();
  @Output() updateFilter = new EventEmitter<any>();
  @Output() deleteFilter = new EventEmitter<any>();
  @Output() openUpdateFilterPopup = new EventEmitter<any>();
  @Output() openConfirmPopup = new EventEmitter<any>();
  @Output() uiOptionChange: EventEmitter<UIOption> = new EventEmitter();
  @Output() resultDataChange: EventEmitter<any> = new EventEmitter();
  @Output() shelfChange: EventEmitter<any> = new EventEmitter();

  get uiOptionValue(): UIOption {
    return this.uiOption;
  }

  set uiOptionValue(value: UIOption) {
    this.uiOption = value;
    this.uiOptionChange.emit(value);
  }

  get shelfValue(): any {
    return this.shelf;
  }

  set shelfValue(value: any) {
    this.shelf = value;
    this.shelfChange.emit(value);
  }

  get resultDataValue(): any {
    return this.resultData;
  }

  set resultDataValue(value: any) {
    this.resultData = value;
    this.resultDataChange.emit(value);
  }
}
