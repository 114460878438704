<div cdkDropList cdkDropListConnectedTo="workflowEditor" *ngIf="favoriteCubeList$ | async as favoriteCubeList">
  <div
    cdkDrag
    cdkDragPreviewContainer="global"
    [cdkDragData]="item.node | rxjsOf"
    *ngFor="let item of favoriteCubeList"
    class="favorite-cube mb-3"
  >
    <span *cdkDragPlaceholder></span>
    <selfai-platform-cube-dnd-placeholder
      *cdkDragPreview
      [cubeUiName]="item.title"
      [operationName]="item.label"
    ></selfai-platform-cube-dnd-placeholder>
    <div class="favorite-cube-label">
      {{ item.title }}
      <br />
      <i>{{ item.label }}</i>
    </div>
    <button
      (click)="removeItem(item)"
      pButton
      class="favorite-cube-remove p-button-text p-button-rounded p-button-link p-button-danger"
      appearance="icon"
      icon="pi pi-trash"
      type="button"
    ></button>
  </div>
</div>
