import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  CubeEditResult,
  CubeWorkflowData,
  GraphNodeUpdateParameterValuesOptions,
  JsonToColumnItem,
  JsonToColumnItemFormGroup,
  JsonToColumnTransformationData,
  JsonToColumnTransformationDataForSave,
  JsonToColumnTransformationFormGroup,
  WorkflowJsonToColumnTransformationData,
  WorkflowSerialized,
} from '@selfai-platform/pipeline-common';
import { DestroyService } from '@selfai-platform/shared';
import { DialogService } from '@selfai-platform/shell';
import { BehaviorSubject, Observable, map, takeUntil } from 'rxjs';
import { WorkflowEditorFacadeservice } from '../../../../workflow-editor';
import {
  extractSelectionItems,
  normalizeData,
  normalizeToLegacyData,
} from '../../../converters/json-to-column-transformation.normalizer';
import { SelectionStoreService } from '../../../services/selection-store.service';

interface StoreData {
  nodeId: string;
  data: JsonToColumnTransformationData;
}

@Injectable()
export class JsonToColumnTransformationComponentService extends BehaviorSubject<StoreData> {
  constructor(
    private readonly destroy$: DestroyService,
    private readonly workflowEditorFacadeService: WorkflowEditorFacadeservice,
    dialogService: DialogService<CubeEditResult, CubeWorkflowData<WorkflowJsonToColumnTransformationData>>,
    selectionStoreService: SelectionStoreService,
  ) {
    let data: StoreData = { data: {} } as StoreData;
    if (dialogService.data) {
      const {
        selectedNode: { id: nodeId, parameters },
      } = dialogService.data;
      if (parameters) {
        data = { nodeId, data: normalizeData(parameters.serialize()) };

        const selectionColumns = extractSelectionItems(parameters);
        if (selectionColumns) {
          selectionStoreService.setSelectionsColumns(selectionColumns);
        }
      }
    }

    super(data);
  }

  setData(data: Partial<StoreData>): void {
    this.next({ ...this.value, ...data });
  }

  // rest fields to except items
  getFormGroup(): FormGroup<JsonToColumnTransformationFormGroup> {
    const {
      data: { sourceColumn, justTypeTransformation, dateTimeFormat, dateFormat },
    } = this.value;

    return new FormGroup<JsonToColumnTransformationFormGroup>({
      sourceColumn: new FormControl(sourceColumn),
      justTypeTransformation: new FormControl(justTypeTransformation),
      dateTimeFormat: new FormControl(dateTimeFormat),
      dateFormat: new FormControl(dateFormat),
    });
  }

  getItemsFormGroups(): Observable<FormGroup<JsonToColumnItemFormGroup>[]> {
    return this.asObservable().pipe(map(({ data: { items } }) => items?.map(this.mapItemToFormGroup)));
  }

  getNodeId(): Observable<string> {
    return this.asObservable().pipe(map(({ nodeId }) => nodeId));
  }

  saveDataToWorkflow(dataForSaving?: JsonToColumnTransformationDataForSave): void {
    this.pipe(takeUntil(this.destroy$)).subscribe((dataFromState) => {
      const data = dataForSaving || dataFromState.data;
      const graphNodeUpdateOptions: GraphNodeUpdateParameterValuesOptions = {
        id: dataFromState.nodeId,
        parameters: normalizeToLegacyData(data),
      };
      this.workflowEditorFacadeService.updateNodeParamterValues(graphNodeUpdateOptions);
    });
  }

  mapItemToFormGroup(item: JsonToColumnItem): FormGroup<JsonToColumnItemFormGroup> {
    return new FormGroup<JsonToColumnItemFormGroup>({
      id: new FormControl(item.id) as FormControl<string>,
      jsonPath: new FormControl(item.jsonPath, [Validators.required]),
      dataType: new FormControl(item.dataType, [Validators.required]),
      columnName: new FormControl(item.columnName),
    });
  }

  private injectDataToWorkflow(
    data: JsonToColumnTransformationDataForSave,
    workflow: WorkflowSerialized,
  ): WorkflowSerialized {
    return {
      ...workflow,
      workflow: {
        ...workflow.workflow,
        nodes: workflow.workflow.nodes.map((node) => {
          if (node.id === this.value.nodeId) {
            return {
              ...node,
              parameters: {
                ...node.parameters,
                ...normalizeToLegacyData(data),
              },
            };
          }

          return node;
        }),
      },
    };
  }
}
