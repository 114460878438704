import { Inject, Injectable, OnDestroy, Optional, Renderer2 } from '@angular/core';
import { CubeEditResult, CubeWorkflowData } from '@selfai-platform/pipeline-common';
import { DialogService } from '@selfai-platform/shell';
import { ConfirmEventType, ConfirmationService } from 'primeng/api';
import { DynamicDialogComponent } from 'primeng/dynamicdialog';
import { ZIndexUtils } from 'primeng/utils';
import { Observable, Subject } from 'rxjs';

// use only on the component is opened of dynamic dialog
@Injectable()
export class DialogHelperService implements OnDestroy {
  documentEscapeListener?: (() => void) | null;

  get maximizedDialog(): boolean {
    return this.dynamicDialogComponent?.maximized || false;
  }

  constructor(
    private readonly renderer: Renderer2,
    private readonly dialogService: DialogService<CubeEditResult, CubeWorkflowData>,
    private readonly confirmationService: ConfirmationService,
    @Inject(DynamicDialogComponent) @Optional() private readonly dynamicDialogComponent?: DynamicDialogComponent,
  ) {}

  ngOnDestroy(): void {
    this.unbindDocumentEscapeListener();
  }

  maximizeDialog(): void {
    this.dynamicDialogComponent?.maximize();
  }

  // validation before closing
  closeDialogWithConfirmation(validationFn?: () => boolean): Observable<void> {
    const sub = new Subject<void>();

    this.confirmationService.confirm({
      message: 'Do you want to save?',
      dismissableMask: true,
      closeOnEscape: true,
      acceptLabel: 'Save',
      rejectLabel: "Don't save",
      acceptButtonStyleClass: 'p-button-success',
      rejectButtonStyleClass: 'p-button-text p-button-link',
      acceptIcon: 'pi pi-save',
      rejectIcon: 'pi pi-trash',
      accept: () => {
        if (validationFn && !validationFn()) {
          sub.error('');
        } else {
          this.dialogService.close({ needSave: true });
          sub.next();
        }
        sub.complete();
      },
      reject: (type: ConfirmEventType) => {
        if (type === ConfirmEventType.REJECT) {
          this.dialogService.close();
        }
      },
    });

    return sub.asObservable();
  }

  bindDocumentEscapeListener(closeFn: () => void) {
    const documentTarget: HTMLElement = this.dynamicDialogComponent?.maskViewChild
      ? this.dynamicDialogComponent?.maskViewChild.nativeElement.ownerDocument
      : 'document';

    this.documentEscapeListener = this.renderer.listen(documentTarget, 'keydown', (event) => {
      if (
        event.which == 27 &&
        Number(this.dynamicDialogComponent?.container.style.zIndex) === ZIndexUtils.getCurrent()
      ) {
        closeFn();
      }
    });
  }

  private unbindDocumentEscapeListener() {
    if (this.documentEscapeListener) {
      this.documentEscapeListener();
      this.documentEscapeListener = null;
    }
  }
}
