<div class="ddp-layout-chart page-chart" [ngClass]="{ 'ddp-page-mapview': selectChart === 'map' }">
  <app-page-loading *ngIf="loadingPage"></app-page-loading>
  <div class="ddp-layout-contents2" #navHeight>
    <div class="ddp-wrap-chart">
      <div class="ddp-wrap-chart">
        <div class="chart--contain" #navWidthCont>
          <selfai-platform-page-accordion
            [dimensionsCount]="getCntShelfItem('DIMENSION')"
            [measuresCount]="getCntShelfItem('MEASURE')"
            [dataLayerKey]="dataLayerKey"
            [selectChartTranslate]="getChartTypeTransLate(selectChart)"
          >
            <div data>
              <selfai-platform-page-section-data
                [dataSourceList]="dataSourceList"
                [dataSource]="dataSource"
                [fieldSearchText]="fieldSearchText"
                [dimensionPageNumber]="dimensionPageNumber"
                [pageMeasures]="pageMeasures"
                [pageDimensions]="pageDimensions"
                [measurePageNumber]="measurePageNumber"
                [isDataMeasureLayerShow]="isDataMeasureLayerShow"
                [measureTotalPage]="measureTotalPage"
                [dimensionTotalPage]="dimensionTotalPage"
                [isDataDimensionLayerShow]="isDataDimensionLayerShow"
                [isContainSearchText]="isContainSearchText"
                (selectDataSource)="selectDataSource($event[0], $event[1])"
                (toggleFilter)="toggleFilter($event[0], $event[1])"
                (onPivotSelect)="onPivotSelect($event[0], $event[1])"
                (openFieldDetailLayer)="openFieldDetailLayer($event[0], $event[1])"
                (openCustomFieldPopup)="openCustomFieldPopup($event[0], $event[1])"
                (onDataPreviewPopup)="onDataPreviewPopup()"
                (setFieldTotalPage)="setFieldTotalPage()"
                (fieldPrev)="fieldPrev($event)"
                (fieldNext)="fieldNext($event)"
                (isCustomMeasureField)="isCustomMeasureField($event)"
                (changeFieldSearchText)="fieldSearchText = $event"
                (changeIsDataDimensionLayerShow)="isDataDimensionLayerShow = $event"
                (changeIsDataMeasureLayerShow)="isDataMeasureLayerShow = $event"
                (changeShowFieldIconsFl)="showFieldIconsFl = $event"
              ></selfai-platform-page-section-data>
            </div>
            <div chart>
              <selfai-platform-page-section-chart
                [recommendCharts]="recommendCharts"
                [selectChart]="selectChart"
                (selectChartChange)="onSelectChartChange($event)"
              ></selfai-platform-page-section-chart>
            </div>
          </selfai-platform-page-accordion>

          <!-- <analysis-component
            style="display: none"
            [selectChart]="selectChart"
            [isChartShow]="isChartShow"
            [widgetConfiguration]="widgetConfiguration"
            [dataLayerKey]="dataLayerKey"
            (clickDataPanelNoti)="clickDataPanel($event)"
            (changeAnalysisPredictionNoti)="changeAnalysisPredictionLine()"
            (changeForecastNoti)="changeForecast()"
            (changeConfidenceNoti)="changeConfidence()"
            [uiOption]="uiOption"
            [shelf]="shelf"
            (changeAnalysisNoti)="changeDraw($event)"
          ></analysis-component> -->
        </div>

        <selfai-platform-page-section-content
          #sectionContent
          [isEditMode]="isEditMode"
          [nav]="nav"
          [rnbMenu]="rnbMenu"
          [isChartShow]="isChartShow"
          [selectChart]="selectChart"
          [widget]="widget"
          [possibleChartCheck]="possibleChartCheck"
          [dashboard]="dashboard"
          [(pivot)]="pivot"
          [(shelf)]="shelf"
          [(uiOption)]="uiOption"
          [widgetConfiguration]="widgetConfiguration"
          [pageDimensions]="pageDimensions"
          [geoType]="geoType"
          [isSankeyNotAllNode]="isSankeyNotAllNode"
          [isNoData]="isNoData"
          [isChartView]="isChartView"
          [isError]="isError"
          [isAvaliableGrid]="isAvaliableGrid"
          [limitInfo]="limitInfo"
          [recommendCharts]="recommendCharts"
          [eventSubject]="eventSubject"
          [$element]="$element"
          [modifyDataVariable]="modifyDataVariable"
          [isPageNameEdit]="isPageNameEdit"
          [editingPageName]="editingPageName"
          (drawChartTooltip)="drawChartTooltip($event)"
          (onDeletePivotItem)="onDeletePivotItem($event)"
          (onChangePivotItem)="onChangePivotItem($event)"
          (onChangePivot)="onChangePivot($event)"
          (toggleFilter)="toggleFilter($event)"
          (onChangePivotFormat)="onChangePivotFormat($event)"
          (openCustomFieldPopup)="openCustomFieldPopup($event)"
          (onShowPopup)="onShowPopup($event)"
          (removeAnalysisLayer)="removeAnalysisLayer($event)"
          (toggleRnb)="toggleRnb($event)"
          (onNameEdit)="onNameEdit($event)"
          (showDataZoom)="showDataZoom($event)"
          (onNameChange)="onNameChange($event)"
          (onSelectChart)="selectChart = $event"
          (onShowGuide)="onShowGuide()"
          (onChangePivotData)="onChangePivotData($event)"
          (chartSelectInfo)="chartSelectInfo($event)"
          (changeDraw)="changeDraw($event)"
          (onNoData)="onNoData()"
        ></selfai-platform-page-section-content>

        <selfai-platform-page-section-buttons
          [isDashboard]="isDashboard"
          [isChartShow]="isChartShow"
          [isError]="isError"
          [isEditMode]="isEditMode"
          (close)="close()"
          (save)="saveLegacy()"
          (toggleEditMode)="toggleEditMode($event)"
        ></selfai-platform-page-section-buttons>
      </div>

      <selfai-platform-page-section-side
        #sectionSide
        [rnbMenu]="rnbMenu"
        [selectChart]="selectChart"
        [possibleChartCheck]="possibleChartCheck"
        [isChartShow]="isChartShow"
        [(uiOption)]="uiOption"
        [(resultData)]="resultData"
        [pivot]="pivot"
        [(shelf)]="shelf"
        [widget]="widget"
        [boardFilters]="boardFilters"
        [widgetConfiguration]="widgetConfiguration"
        [dashboard]="dashboard"
        (onSetDrawChartParam)="onSetDrawChartParam($event)"
        (changeAxisByStack)="changeAxisByStack($event)"
        (onShowPopup)="onShowPopup($event)"
        (onFormatCommonChange)="onFormatCommonChange($event)"
        (onFormatEachChange)="onFormatEachChange($event)"
        (updateFilter)="updateFilter($event)"
        (deleteFilter)="deleteFilter($event)"
        (openUpdateFilterPopup)="openUpdateFilterPopup($event)"
        (openConfirmPopup)="openConfirmPopup($event[0], $event[1])"
      ></selfai-platform-page-section-side>
    </div>
    <app-custom-field
      *ngIf="isShowCustomFiled"
      [fields]="fields"
      [dataSource]="widget.configuration.dataSource"
      [customFields]="widget.configuration.customFields"
      [customField]="selectedCustomField"
      [selectedColumnType]="columnType"
      (updateColumn)="updateCustomFields($event)"
      (closeDialog)="isShowCustomFiled = false"
    ></app-custom-field>
    <app-confirm-modal (confirm)="confirm($event)"></app-confirm-modal>
    <data-preview
      *ngIf="isShowDataDetail"
      [field]="selectedField"
      [singleTab]="isColumnDetail"
      [initial]="dataSource"
      [source]="widget.dashBoard"
      (close)="isShowDataDetail = false"
    ></data-preview>
  </div>
  <page-data-context
    (openCustomFieldEvent)="openCustomFieldPopup($event)"
    (openColumnDetailEvent)="onColumnDetailPopup($event)"
    (deleteCustomFieldEvent)="deleteCustomField($event)"
    (changeAlias)="changeDatasourceFieldAlias($event)"
  ></page-data-context>
  <app-config-filter (done)="configureFilter($event)" (close)="closeFilterPopup()"></app-config-filter>
</div>
