import { Injectable, Injector } from '@angular/core';

import { SelfaiAuthService } from '@selfai-platform/shared';

import { AuthService } from '../../auth/auth.service';
import { CookieConstant } from '../../common/constant/cookie.constant';
import { AbstractService } from '../../common/service/abstract.service';

@Injectable()
export class UserService extends AbstractService {
  constructor(
    protected readonly injector: Injector,
    private readonly selfaiAuthService: SelfaiAuthService,
    private readonly authService: AuthService,
  ) {
    super(injector);
  }

  public async isLoggedIn(): Promise<boolean> {
    const loggedIn = await this.selfaiAuthService.getProvider().isLoggedIn();

    if (loggedIn) {
      const token = this.cookieService.get(CookieConstant.KEY.LOGIN_TOKEN);
      if (!token) {
        this.authService.setCredentialsFromFromProvider();
      }
    }

    return loggedIn;
  }
}
