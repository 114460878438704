import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { KdBackendApiService } from '../../common';
import { FavoriteDomainApi } from '../models';

@Injectable()
export class FavoriteApiService {
  constructor(private readonly kdBackendApiService: KdBackendApiService) {}

  addToFavorite(domain: FavoriteDomainApi, id: string): Observable<void> {
    return this.kdBackendApiService.post<void>(`${domain}/${id}/favorite`, null);
  }

  removeFromFavorite(domain: FavoriteDomainApi, id: string): Observable<void> {
    return this.kdBackendApiService.delete<void>(`${domain}/${id}/favorite`);
  }
}
