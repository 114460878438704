import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ErrorDialogComponentModule } from '../dialogs/error-dialog/error-dialog.module';
import { DialogHeaderComponent } from './dialog-header.component';
import { ErrorButtonComponent } from './error-button/error-button.component';

@NgModule({
  imports: [CommonModule, ButtonModule, ErrorDialogComponentModule, OverlayPanelModule],
  declarations: [DialogHeaderComponent, ErrorButtonComponent],
  exports: [DialogHeaderComponent],
})
export class DialogHeaderComponentModule {}
