import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { StepVariableFormGroup, TypeValuesFormGroup, VariableMode } from '@selfai-platform/pipeline-common';
import { DestroyService } from '@selfai-platform/shared';
import { delay, startWith, takeUntil } from 'rxjs/operators';
import { JSON_PATH_INIT_FORM_VALUE } from '../../../const';
import { orderIndentDown, orderIndentUp } from '../../../utils';

@Component({
  selector: 'selfai-platform-step-variable',
  templateUrl: './step-variable.component.html',
  styleUrls: ['./step-variable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class StepVariableComponent implements OnInit {
  @Input()
  form!: FormGroup<StepVariableFormGroup> | UntypedFormGroup;

  get variableValue(): FormGroup<TypeValuesFormGroup> {
    return (this.form as FormGroup<StepVariableFormGroup>).controls.variableValue;
  }

  get variablePartOfIndexFormArray(): StepVariableFormGroup['variablePartOfIndex'] {
    return (this.form as FormGroup<StepVariableFormGroup>).controls.variablePartOfIndex;
  }

  get variablePartOfIndexControls(): FormGroup<TypeValuesFormGroup>[] | undefined {
    return this.variablePartOfIndexFormArray?.controls;
  }

  modeItems = Object.values(VariableMode);

  constructor(private readonly destroy$: DestroyService, private readonly fb: FormBuilder) {}

  ngOnInit(): void {
    (this.form as FormGroup<StepVariableFormGroup>).controls.variableMode.valueChanges
      .pipe(startWith(null), takeUntil(this.destroy$), delay(0))
      .subscribe(() => this.makeFormValidation());
  }

  addPartOfIndexControl(): void {
    this.variablePartOfIndexFormArray.push(this.fb.group(JSON_PATH_INIT_FORM_VALUE) as FormGroup<TypeValuesFormGroup>);
  }

  removePartOfIndexControl(index: number): void {
    this.variablePartOfIndexFormArray.removeAt(index);
  }

  orderUp(index: number): void {
    orderIndentUp(this.variablePartOfIndexFormArray, index);
  }

  orderDown(index: number): void {
    orderIndentDown(this.variablePartOfIndexFormArray, index);
  }

  private makeFormValidation(): void {
    const { variableMode } = this.form.value;
    this.form.controls.variableName.setValidators(Validators.required);
    this.form.controls.variableName.updateValueAndValidity();

    if (variableMode === VariableMode.CALC) {
      this.form.controls.variableMapName.clearValidators();
      this.form.controls.variableMapName.updateValueAndValidity();

      this.variablePartOfIndexControls?.forEach(({ controls }) => {
        controls.value.clearValidators();
        controls.value.updateValueAndValidity();
      });

      this.variableValue.controls.value.setValidators(Validators.required);
      this.variableValue.controls.value.updateValueAndValidity();
    }
    if (variableMode === VariableMode.READ || variableMode === VariableMode.WRITE) {
      this.variableValue.controls.value.clearValidators();
      this.variableValue.controls.value.updateValueAndValidity();

      this.form.controls.variableMapName.setValidators(Validators.required);
      this.form.controls.variableMapName.updateValueAndValidity();

      this.variablePartOfIndexControls?.forEach(({ controls }) => {
        controls.value.setValidators(Validators.required);
        controls.value.updateValueAndValidity();
      });
    }

    this.form.markAllAsTouched();
  }
}
