import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsedInPanelComponent } from './components/used-in-panel/used-in-panel.component';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { OverlayPanelModule } from 'primeng/overlaypanel';

@NgModule({
  declarations: [UsedInPanelComponent],
  imports: [CommonModule, ButtonModule, TooltipModule, OverlayPanelModule],
  exports: [UsedInPanelComponent]
})
export class UsedInPanelModule {}
