import { InjectionToken } from '@angular/core';
import { DatasourceTypes } from '@selfai-platform/pipeline-common';

export const KE_AVALIABLE_DATASOURCES = new InjectionToken<DatasourceTypes[]>('AvaliableDatasources', {
  factory: () => {
    return [
      DatasourceTypes.externalFile,
      DatasourceTypes.googleSpreadsheet,
      DatasourceTypes.hdfs,
      DatasourceTypes.jdbc,
      DatasourceTypes.kafka,
      DatasourceTypes.libraryFile,
      DatasourceTypes.s3,
    ];
  },
});
