import {
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';

import * as _ from 'lodash';

import {
  Dashboard,
  TimeListFilter,
  DatasourceField as Field,
  createCandidate,
  Candidate,
  CustomField,
} from '@selfai-platform/bi-domain';

import { DatasourceService } from '../../../datasource/service/datasource.service';
import { AbstractFilterPopupComponent } from '../abstract-filter-popup.component';

@Component({
  selector: 'app-time-list-filter',
  templateUrl: 'time-list-filter.component.html',
})
export class TimeListFilterComponent extends AbstractFilterPopupComponent implements OnInit, OnDestroy {
  private _isApiSort = true;

  private _candidateList: Candidate[] = [];

  private _selectedValues: Candidate[] = [];
  private _candidateValues: Candidate[] = [];

  public useAll = false;

  public isOnlyShowCandidateValues = false;

  public pageCandidateList: Candidate[] = [];
  public currentPage = 1;
  public lastPage = 1;
  public pageSize = 15;
  public totalCount = 0;
  public totalItemCnt = 0;

  public targetFilter: TimeListFilter;

  @Input()
  public inputFilter: TimeListFilter;

  @Input()
  public dashboard: Dashboard;

  @Input()
  public field: Field | CustomField;

  @Input()
  public mode = 'CHANGE';

  @Output()
  public changeFilter: EventEmitter<TimeListFilter> = new EventEmitter();

  constructor(
    private datasourceService: DatasourceService,
    protected elementRef: ElementRef,
    protected injector: Injector,
  ) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngOnChanges(changes: SimpleChanges) {
    const filterChanges: SimpleChange = changes.inputFilter;
    const prevFilter: TimeListFilter = filterChanges.previousValue;
    const currFilter: TimeListFilter = filterChanges.currentValue;
    if (
      currFilter &&
      (!prevFilter ||
        prevFilter.field !== currFilter.field ||
        prevFilter.timeUnit !== currFilter.timeUnit ||
        0 < _.difference(prevFilter.valueList, currFilter.valueList).length ||
        0 < _.difference(prevFilter.candidateValues, currFilter.candidateValues).length)
    ) {
      this.setData(filterChanges.currentValue, !filterChanges.firstChange);
    }
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public setData(filter: TimeListFilter, isBroadcast: boolean = false) {
    if (filter.valueList && 0 < filter.valueList.length) {
      this._selectedValues = filter.valueList.map((item) => this._stringToCandidate(item));
    }
    if (filter.candidateValues && 0 < filter.candidateValues.length) {
      this._candidateValues = filter.candidateValues.map((item) => this._stringToCandidate(item));
      if ('CHANGE' !== this.mode) {
        this.isOnlyShowCandidateValues = true;
      }
    }

    this._loadCandidateList(filter, 'VALUE', isBroadcast);
  }

  public getData(): TimeListFilter {
    const filter: TimeListFilter = this.targetFilter;
    filter.valueList = this._selectedValues.map((item) => item.name);
    filter.candidateValues = this._candidateValues.map((item) => item.name);
    return filter;
  }

  public sortCandidateValues(filter: TimeListFilter, target: string, type: string) {
    if (this._isApiSort) {
      this._loadCandidateList(filter, 'FREQUENCY' === target ? 'COUNT' : 'VALUE');
    } else {
      filter['sortTarget'] = target;
      filter['sortType'] = type;

      const allCandidates: Candidate[] = _.cloneDeep(this._candidateList);
      if ('FREQUENCY' === target) {
        allCandidates.sort((val1: Candidate, val2: Candidate) => {
          return 'ASC' === type ? val1.count - val2.count : val2.count - val1.count;
        });
      } else {
        allCandidates.sort((val1: Candidate, val2: Candidate) => {
          const name1: string = val1.name ? val1.name.toUpperCase() : '';
          const name2: string = val2.name ? val2.name.toUpperCase() : '';
          if (name1 < name2) {
            return 'ASC' === type ? -1 : 1;
          }
          if (name1 > name2) {
            return 'ASC' === type ? 1 : -1;
          }
          return 0;
        });
      }
      this._candidateList = allCandidates;

      if ('WIDGET' === this.mode) {
        this.pageCandidateList = _.cloneDeep(this._candidateList);
      } else {
        this.setCandidatePage(1, true);
      }
    }
  }

  public setCandidatePage(page: number, isInitial: boolean = false) {
    if (isInitial) {
      this.pageCandidateList = [];
      this.currentPage = 1;
      this.lastPage = 1;
      this.totalCount = 0;
    }

    if (page <= 0) return;
    if (this.lastPage < page) return;

    this.currentPage = page;
    let start = 0;
    let end = 0;

    if (this._candidateList && 0 < this._candidateList.length) {
      let pagedList: Candidate[] = _.cloneDeep(this._candidateList);

      if (this.isOnlyShowCandidateValues) {
        pagedList = pagedList.filter((item) => this.isShowItem(item));
      }

      this.totalCount = pagedList.length;

      this.lastPage =
        this.totalCount % this.pageSize === 0
          ? this.totalCount / this.pageSize
          : Math.floor(this.totalCount / this.pageSize) + 1;

      start = page * this.pageSize - this.pageSize;
      end = page * this.pageSize - 1;
      if (end > this.totalCount) {
        end = this.totalCount;
      }

      this.pageCandidateList = pagedList.slice(start, end);
    }
  }

  public candidateSelectAll(event: any) {
    const checked = event.target ? event.target.checked : event.currentTarget.checked;
    if (checked) {
      this._selectedValues = _.cloneDeep(this._candidateList);
    } else {
      this._selectedValues = [];
    }
    this.changeFilter.emit(this.getData());
  }

  public isCheckedAllItem(): boolean {
    return this._selectedValues.length === this._candidateList.length;
  }

  public isCheckedItem(listItem: Candidate): boolean {
    return -1 < this._selectedValues.findIndex((item) => item.name === listItem.name);
  }

  public isShowItem(listItem: Candidate): boolean {
    return -1 < this._candidateValues.findIndex((item) => item.name === listItem.name);
  }

  public candidateSelect(item: Candidate, event: any) {
    const checked = event.target ? event.target.checked : event.currentTarget.checked;
    if (checked) {
      this._selectedValues.push(item);
    } else {
      _.remove(this._selectedValues, { name: item.name });
    }

    this.changeFilter.emit(this.getData());
  }

  public setOnlyShowCandidateValues() {
    this.isOnlyShowCandidateValues = !this.isOnlyShowCandidateValues;
    this.setCandidatePage(1, true);
  }

  public candidateShowToggle(item: Candidate) {
    if (this.isShowItem(item)) {
      _.remove(this._candidateValues, { name: item.name });
    } else {
      this._candidateValues.push(item);
    }

    if (this.isOnlyShowCandidateValues) {
      this.setCandidatePage(1, true);
    }
  }

  private _loadCandidateList(filter: TimeListFilter, sortBy: string = 'VALUE', isBroadcast: boolean = false) {
    this.loadingShow();
    this.datasourceService
      .getCandidateForFilter(filter, this.dashboard, [], null, sortBy)
      .then((result) => {
        this._setCandidateResult(result, filter, sortBy);
        this.targetFilter = filter;
        this.safelyDetectChanges();

        if (this.field) {
          this.useAll = !(-1 < this.field.filteringSeq);
        } else {
          this.useAll = true;
        }
        if (false === this.useAll && 0 === this._selectedValues.length) {
          this._selectedValues.push(this._candidateList[0]);
        }

        isBroadcast && this.changeFilter.emit(this.getData());

        this.loadingHide();
      })
      .catch((err) => this.commonExceptionHandler(err));
  }

  private _setCandidateResult(result: any[], targetFilter: TimeListFilter, sortBy: string = 'VALUE') {
    this._candidateList = [];

    result.forEach((item) => {
      const candidate = createCandidate({
        name: item['field'],
        count: item['count'],
      });
      this._candidateList.push(candidate);
    });
    targetFilter.candidateValues || (targetFilter.candidateValues = []);
    this.totalItemCnt = this._candidateList.length;

    if (this._isApiSort) {
      targetFilter['sortTarget'] = 'VALUE' === sortBy ? 'ALPHNUMERIC' : 'FREQUENCY';
      targetFilter['sortType'] = 'ASC';

      if ('WIDGET' === this.mode) {
        this.pageCandidateList = _.cloneDeep(this._candidateList);
      } else {
        this.setCandidatePage(1, true);
      }
    } else {
      this.sortCandidateValues(targetFilter, 'ALPHNUMERIC', 'ASC');
    }
  }

  private _stringToCandidate(item: string, isDefine: boolean = false): Candidate {
    const candidate = createCandidate({
      name: item,
      count: 0,
      isDefinedValue: isDefine,
    });
    return candidate;
  }
}
