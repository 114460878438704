import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { GraphNodeOptions } from '@selfai-platform/pipeline-common';
import { DialogService } from '@selfai-platform/shell';
import { firstValueFrom, map, Observable } from 'rxjs';
import { FavoriteCubeListService } from '../../services/favorite-cubes/favorite-cube-list.service';

@Component({
  selector: 'selfai-platform-favorite-cube-add',
  templateUrl: './favorite-cube-add.component.html',
  styleUrls: ['./favorite-cube-add.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FavoriteCubeAddComponent implements OnInit {
  formControlName = new FormControl('', {
    validators: Validators.required,
    asyncValidators: this.validateUnique.bind(this),
    updateOn: 'change',
  });

  constructor(
    private readonly favoriteCubeListService: FavoriteCubeListService,
    private readonly dialogService: DialogService<never, GraphNodeOptions>,
  ) {}

  ngOnInit(): void {
    this.favoriteCubeListService.loadList();

    if (this.dialogService.data) {
      this.formControlName.setValue(this.dialogService.data.uiName || this.dialogService.data.operation.name);
    }
  }

  onSubmit(): void {
    if (this.formControlName.valid && this.dialogService.data) {
      const node = this.dialogService.data;
      node.uiName = this.formControlName.value as string;
      this.favoriteCubeListService.addItem({ node, label: node.uiName });
      this.dialogService.close();
    }
  }

  private validateUnique(control: AbstractControl): Promise<{ notUnique: boolean } | null> {
    const value = control.value as string;

    const validationResult$: Observable<{ notUnique: boolean } | null> = this.favoriteCubeListService.getList().pipe(
      map((favoriteList) => {
        const node = this.dialogService.data as GraphNodeOptions;
        const isNotUnique = favoriteList.some(
          ({ node: { operation, uiName } }) => value === uiName && node.operation.id === operation.id,
        );

        return isNotUnique ? { notUnique: true } : null;
      }),
    );

    return firstValueFrom(validationResult$);
  }
}
