import { Routes } from '@angular/router';

import { AppAuthGuard } from '@selfai-platform/shared';
import {
  KNOWLEDGE_DESIGNER_ROOT_ROUTE,
  PATH_MAIN_DASHBOARD,
  PATH_RECYCLEBIN_ADMIN,
  PATH_RECYCLEBIN_USER,
} from '@selfai-platform/shell';

import { AppComponent } from './app.component';
import {
  ConnectionListGuard,
  DatasourceManagementGuard,
  KdCookieGuard,
  RecyclebinAdminGuard,
  StagedbEnabledGuard,
  WorkspacesCreateGuard,
  WorkspacesEditGuard,
  WorkspacesViewGuard,
} from './common';
import { MainDashboardComponent } from './dashboard/component/main-dashboard/main-dashboard.component';
import { LayoutComponent } from './layout/layout.component';

export function getBiRoutes() {}

export const BiRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/' + PATH_MAIN_DASHBOARD,
  },
  {
    path: PATH_MAIN_DASHBOARD,
    pathMatch: 'full',
    canActivate: [AppAuthGuard, WorkspacesViewGuard],
    loadChildren: () =>
      import('./dashboard/component/main-dashboard/main-dashboard.module').then((m) => m.MainDashboardComponentModule),
    component: MainDashboardComponent,
  },
  {
    path: KNOWLEDGE_DESIGNER_ROOT_ROUTE,
    canActivate: [AppAuthGuard],
    children: [
      { path: '', redirectTo: 'workspaces', pathMatch: 'full' },
      {
        path: 'workspaces',
        pathMatch: 'full',
        loadChildren: () => import('./lazy-modules/workspace-list.module').then((m) => m.WorkspaceListComponentModule),
        canActivate: [WorkspacesViewGuard],
      },
      {
        path: 'workspace/create',
        loadChildren: () =>
          import('./lazy-modules/workspaces-roles-manager.module').then((m) => m.WorkspacesRolesManagerModule),
        canActivate: [WorkspacesCreateGuard],
      },
      {
        path: 'workspace/:workspaceId',
        loadChildren: () => import('./lazy-modules/workbook-list.module').then((m) => m.WorkbookListComponentModule),
        canActivate: [WorkspacesViewGuard],
      },
      {
        path: 'workbook/:workbookId',
        loadChildren: () =>
          import('./lazy-modules/workbook-dashboard-list.module').then((m) => m.WorkbookDashboardListComponentModule),
        canActivate: [WorkspacesViewGuard],
      },
      {
        path: 'workspace/:workspaceId/manage-roles',
        loadChildren: () =>
          import('./lazy-modules/workspaces-roles-manager.module').then((m) => m.WorkspacesRolesManagerModule),
        canActivate: [WorkspacesEditGuard],
      },
      {
        path: 'charts',
        loadChildren: () => import('./lazy-modules/chart-list.module').then((m) => m.ChartListModule),
      },
      {
        path: 'dashboards',
        loadChildren: () => import('./lazy-modules/dashboard-list.module').then((m) => m.DashboardListModule),
      },
      {
        path: 'preview',
        children: [
          {
            path: 'dashboard/:dashboardId',
            loadChildren: () =>
              import('./dashboard/component/dashboard-preview').then((m) => m.DashboardPreviewComponentModule),
            canActivate: [WorkspacesViewGuard],
          },
          {
            path: 'widget/:widgetId',
            loadChildren: () => import('./widget-preview').then((m) => m.WidgetPreviewComponentModule),
            canActivate: [WorkspacesViewGuard],
          },
        ],
      },
      {
        path: PATH_RECYCLEBIN_USER,
        pathMatch: 'full',
        loadChildren: () => import('./lazy-modules/recycle-bin.module').then((m) => m.RecycleBinModule),
      },
      {
        path: PATH_RECYCLEBIN_ADMIN,
        pathMatch: 'full',
        loadChildren: () => import('./lazy-modules/recycle-bin.module').then((m) => m.RecycleBinModule),
        canActivate: [RecyclebinAdminGuard],
      },
    ],
  },
  {
    path: 'bi',
    canActivate: [AppAuthGuard],
    component: AppComponent,
    children: [
      {
        path: '',
        component: LayoutComponent,
        canActivate: [KdCookieGuard, StagedbEnabledGuard, ConnectionListGuard],
        children: [
          {
            path: 'workbook',
            loadChildren: () => import('./workbook/workbook.module').then((m) => m.WorkbookModule),
            canActivate: [WorkspacesViewGuard],
          },
          {
            path: 'management/storage',
            loadChildren: () => import('./data-storage/data-storage.module').then((m) => m.DataStorageModule),
            canActivate: [DatasourceManagementGuard],
          },
          {
            path: 'management/datapreparation',
            loadChildren: () =>
              import('./data-preparation/data-preparation.module').then((m) => m.DataPreparationModule),
          },

          // NOT Used now. Maybe will be used in future. Do not remove commented routes
          //   {
          //     path: 'dashboard',
          //     loadChildren: () => import('./embedded/embedded-view.module').then((m) => m.EmbeddedViewModule),
          //   },
          //   {
          //     path: 'embedded',
          //     loadChildren: () => import('./embedded/embedded-view.module').then((m) => m.EmbeddedViewModule),
          //   },
          //   {
          //     path: 'edit-page',
          //     loadChildren: () => import('./page/page.module').then((m) => m.PageModule),
          //   },

          // NOT Used now. Maybe will be used in future. Do not remove commented routes
          //   {
          //     path: 'management/model',
          //     loadChildren: () =>
          //       import('./model-management/model-management.module').then((m) => m.ModelManagementModule),
          //     canActivate: [DatasourceManagementGuard],
          //   },
          // {
          //   path: 'management/monitoring',
          //   loadChildren: () => import('./monitoring/monitoring.module').then((m) => m.MonitoringModule),
          //   //canActivate: [DatasourceManagementGuard],
          // },
          //   {
          //     path: 'management/metadata',
          //     loadChildren: () =>
          //       import('./meta-data-management/meta-data-management.module').then((m) => m.MetaDataManagementModule),
          //     canActivate: [MetadataManagementGuard],
          //   },
          // {
          //   path: 'management/engine-monitoring',
          //   loadChildren: () =>
          //     import('./engine-monitoring/engine-monitoring.module').then((m) => m.EngineMonitoringModule),
          //   //canActivate: [DatasourceManagementGuard],
          // },
          //
          //   {
          //     path: 'notebook',
          //     loadChildren: () => import('./notebook/notebook.module').then((m) => m.NotebookModule),
          //   },
          //   {
          //     path: 'workbench',
          //     loadChildren: () => import('./workbench/workbench.module').then((m) => m.WorkbenchModule),
          //   },
          // {
          //   path: 'exploredata',
          //   loadChildren: () => import('./explore-data/explore-data.module').then((m) => m.ExploreDataModule),
          // },
        ],
      },
      { path: '**', redirectTo: '', pathMatch: 'full' },
    ],
  },
];
