import { Injectable } from '@angular/core';
import { PermissionService, PipelinePermissionEntity, WorkflowPermissionAction } from '@selfai-platform/shared';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject, switchMap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PipelineMenuItemService {
  private pipelineMenuItems = new BehaviorSubject<MenuItem[]>([]);
  pipelineMenuItems$ = this.pipelineMenuItems.asObservable();

  constructor(private readonly permissionService: PermissionService) {
    this.permissionService.pipelinePermissionsLoadedObs$
      .pipe(
        switchMap(() =>
          this.permissionService.checkPermission({
            entity: PipelinePermissionEntity.Workflows,
            action: WorkflowPermissionAction.List,
          }),
        ),
      )
      .subscribe((hasWorkflowListViewPermission) => {
        this.setMenuItemsState(hasWorkflowListViewPermission);
      });
  }

  private setMenuItemsState(hasWorkflowListViewPermission: boolean) {
    const menuItems: MenuItem[] = [];

    if (hasWorkflowListViewPermission) {
      menuItems.push({
        id: 'engine',
        label: 'shell.menu.knowledge-engine',
        state: { sort: 300 },
        items: [
          {
            label: 'shell.menu.workflows',
            icon: 'pi pi-fw pi-sitemap',
            routerLink: '/pipeline',
          },
        ],
      });
    }

    this.pipelineMenuItems.next(menuItems);
  }
}
