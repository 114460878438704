export enum LocalStorageKey {
  MENU_STATE = 'menuState',
  CURRENT_USER_LANGUAGE = 'currentUserLanguage',
  FAVORITE_CUBES = 'pipelineFavoriteCubeList',
  WORKFLOW_VERSIONS = 'pipelineWorkflowVersions',
  PREFIX_SETTINGS = 'prefixSettings',
  WORKSPACE_LIST_PREFIX_SETTINGS = 'workspaceListPrefixSettings',
  WORKBOOK_LIST_PREFIX_SETTINGS = 'dashboardListPrefixSettings',
  WORKBOOK_DASHBOARD_LIST_PREFIX_SETTINGS = 'workbookDashboardListPrefixSettings',
  AGGREGATOR_PROCESS_LIST_PREFIX_SETTINGS = 'aggregatorProcessListPrefixSettings',
  AGGREGATOR_ROUTE_LIST_PREFIX_SETTINGS = 'aggregatorRouteListPrefixSettings',
  WORKFLOW_LIST_PREFIX_SETTINGS = 'workflowListPrefixSettings',
  TABLE_COL_SIZING = 'tableColSizing',
}
