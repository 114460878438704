import {
  ApplicationRef,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import { Message } from '@stomp/stompjs';
import * as _ from 'lodash';

import {
  BoardConfiguration,
  BoardDataSource,
  Dashboard,
  createBoardConfiguration,
  createBoardSource,
  createDashboard,
  WildCardFilter,
  Datasource,
  DatasourceField as Field,
  FieldRole,
  LogicalType,
  createWildCardFilter,
  TimeFilter,
  TimeRangeFilter,
  MeasurePositionFilter,
  createMeasurePositionFilter,
  MeasureInequalityFilter,
  createMeasureInequalityFilter,
  InclusionFilter,
  Filter,
  FilteringType,
  TimeUnit,
  BoundFilter,
} from '@selfai-platform/bi-domain';

import { CommonConstant } from '../../../common/constant/common.constant';
import { CookieConstant } from '../../../common/constant/cookie.constant';
import { DatasourceService } from '../../../datasource/service/datasource.service';
import { DashboardUtil } from '../../util/dashboard.util';
import { FilterUtil } from '../../util/filter.util';
import { AbstractFilterPopupComponent } from '../abstract-filter-popup.component';
import { ConfigureFiltersBoundComponent } from '../bound-filter/configure-filters-bound.component';
import { ConfigureFiltersInclusionComponent } from '../inclusion-filter/configure-filters-inclusion.component';
import { ConfigureFiltersTimeComponent } from '../time-filter/configure-filters-time.component';

@Component({
  selector: 'app-essential-filter',
  templateUrl: './essential-filter.component.html',
})
export class EssentialFilterComponent extends AbstractFilterPopupComponent implements OnInit, OnDestroy {
  private _pseudoDashboard: Dashboard;

  private _dataSource: BoardDataSource;
  private _dataSourceId: string;

  private _compMap: any = {};

  private _ingestResultFilters: Filter[] = [];

  @Input('datasource')
  public inputDatasource: BoardDataSource;

  @Output()
  public done: EventEmitter<{
    id: string;
    info: Datasource;
    dataSourceId: string;
    filters?: Filter[];
  }> = new EventEmitter();

  public essentialFilters: Filter[] = [];

  public logicalType = LogicalType;

  public isShowProgress = false;
  public ingestionStatus: { progress: number; message: string; step?: number };

  constructor(
    private dataSourceService: DatasourceService,
    protected appRef: ApplicationRef,
    protected componentFactoryResolver: ComponentFactoryResolver,
    protected elementRef: ElementRef,
    protected injector: Injector,
  ) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();

    this._dataSource = createBoardSource();
    const mainDs: BoardDataSource = this.inputDatasource;

    this._setFields(mainDs.uiFields);

    if (mainDs.temporary) {
      this._dataSource.type = 'default';
      this._dataSource.connType = 'LINK';
      this._dataSource.temporary = mainDs.temporary;
      this._dataSource.name = mainDs.metaDataSource.name;
      this._dataSource.engineName = mainDs.metaDataSource.engineName;
      this._dataSourceId = mainDs.id;

      if (mainDs.metaDataSource['filters']) {
        this.essentialFilters = mainDs.metaDataSource['filters'];
      }
    } else {
      this._dataSource.type = 'default';
      this._dataSource.connType = 'LINK';
      this._dataSource.name = mainDs.name;
      this._dataSource.engineName = mainDs.engineName;
      this._dataSourceId = mainDs.id;
      if (mainDs.uiFilters && 0 < mainDs.uiFilters.length) {
        this.essentialFilters = mainDs.uiFilters;
      } else {
        this.essentialFilters = this._setEssentialFilters(mainDs.uiFields);
      }
    }

    this._pseudoDashboard = createDashboard();
    this._pseudoDashboard.configuration = createBoardConfiguration();
    this._pseudoDashboard.configuration.dataSource = this._dataSource;
    this._pseudoDashboard.configuration.fields = mainDs.uiFields;

    if (0 < this.essentialFilters.length) {
      this._convertServerSpecToUISpec(this.essentialFilters);
    } else {
      this.ingest();
    }
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public filterUtil = FilterUtil;

  // , isDimension: boolean = true
  public getIconClass(filter: Filter): Field {
    return this._getField(filter.field, filter.ref);
  }

  public startComponent(elm: ElementRef, filter: Filter) {
    const field: Field = this._getField(filter.field, filter.ref);
    if ('include' === filter.type) {
      const confFilterCompFactory = this.componentFactoryResolver.resolveComponentFactory(
        ConfigureFiltersInclusionComponent,
      );
      const inclusionComp = this.appRef.bootstrap(confFilterCompFactory, elm.nativeElement).instance;
      inclusionComp.showComponent(this._pseudoDashboard, <InclusionFilter>filter, field, false);
      this._compMap[filter.field] = inclusionComp;
    } else if (FilterUtil.isTimeFilter(filter)) {
      const confFilterCompFactory =
        this.componentFactoryResolver.resolveComponentFactory(ConfigureFiltersTimeComponent);
      const timeComp = this.appRef.bootstrap(confFilterCompFactory, elm.nativeElement).instance;
      timeComp.showComponent(this._pseudoDashboard, <TimeFilter>filter, field, false);
      this._compMap[filter.field] = timeComp;
    } else if ('bound' === filter.type) {
      const confFilterCompFactory =
        this.componentFactoryResolver.resolveComponentFactory(ConfigureFiltersBoundComponent);
      const boundComp = this.appRef.bootstrap(confFilterCompFactory, elm.nativeElement).instance;
      boundComp.showComponent(this._pseudoDashboard, <BoundFilter>filter, field);
      this._compMap[filter.field] = boundComp;
    }
  }

  public ingest() {
    this.ingestionStatus = { progress: 0, message: '', step: 1 };
    this.isShowProgress = true;

    this._ingestResultFilters = this.essentialFilters.map((item) => this._compMap[item.field].getData());
    let filterParams: Filter[] = _.cloneDeep(this._ingestResultFilters);

    for (let filter of filterParams) {
      filter = FilterUtil.convertToServerSpec(filter);
      if ('include' === filter.type && filter['candidateValues']) {
        delete filter['candidateValues'];
      }
    }

    filterParams = filterParams.filter((item) => {
      if ('bound' === item.type) {
        return null !== item['min'];
      } else if ('include' === item.type) {
        return item['valueList'] && 0 < item['valueList'].length;
      } else {
        return !FilterUtil.isTimeAllFilter(item);
      }
    });
  }

  public closeProgress() {
    this.isShowProgress = false;
    if (0 === this.essentialFilters.length) {
      this.closeEvent.emit();
    }
  }

  private _getCandidateParam(filters: Filter[], filter: Filter, dataSource: BoardDataSource): any {
    const param: any = {};
    param.dataSource = DashboardUtil.getDataSourceForApi(_.cloneDeep(dataSource));
    param.filters = filters ? _.cloneDeep(filters) : [];
    param.filters = param.filters
      .map((item) => FilterUtil.convertToServerSpec(item))
      .filter((item) => !(item.type === 'bound' && item['min'] == null));

    if (filter.type === 'include') {
      param.targetField = {
        alias: filter.field,
        name: filter.field,
        type: 'dimension',
      };
    } else if (FilterUtil.isTimeFilter(filter)) {
      const timeFilter: TimeFilter = <TimeFilter>filter;
      param.targetField = {
        type: 'timestamp',
        name: timeFilter.field,
        alias: timeFilter.field,
        format: {
          type: 'time_continuous',
          discontinuous: FilterUtil.isDiscontinuousTimeFilter(timeFilter),
          unit: timeFilter.timeUnit,
          filteringType: FilterUtil.isTimeListFilter(timeFilter) ? FilteringType.LIST : FilteringType.RANGE,
        },
      };
      timeFilter.byTimeUnit && (param.targetField.format.byUnit = timeFilter.byTimeUnit);
      param.sortBy = 'VALUE';
    }

    return param;
  }

  private _setEssentialFilters(fields: Field[]) {
    const filters: Filter[] = [];
    let timeFilter: Filter;

    fields.forEach((field: Field) => {
      if (field.filtering) {
        if (FieldRole.DIMENSION === field.role) {
          if (field.logicalType === LogicalType.TIMESTAMP) {
            filters.push(FilterUtil.getTimeRangeFilter(field, TimeUnit.NONE, 'essential'));
          } else {
            const inclusionFilter: InclusionFilter = FilterUtil.getBasicInclusionFilter(field, 'essential');

            inclusionFilter['showSortLayer'] = false;
            filters.push(inclusionFilter);
          }
        } else if (FieldRole.MEASURE === field.role) {
          filters.push(FilterUtil.getBasicBoundFilter(field, 'essential'));
        }
      }
    });

    filters.sort((a: Filter, b: Filter) => a.ui.filteringSeq - b.ui.filteringSeq);
    timeFilter && filters.unshift(timeFilter);

    return filters;
  }

  private _setEssentialFilter(filters: Filter[], idx: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      let index = idx;

      const filter = _.cloneDeep(this.essentialFilters[index]);

      const params: any = this._getCandidateParam(filters, filter, this._dataSource);

      this.dataSourceService
        .getCandidate(params)
        .then((result) => {
          if ('include' === filter.type) {
            if (result && result.length > 0) {
              const apiFieldName: string = filter.field.replace(/(\S+\.)\S+/gi, '$1field');
              result = result.map((item) => {
                return { field: item[apiFieldName], count: item['.count'] };
              });

              if (result[0].field) (<InclusionFilter>filter).valueList = [result[0].field];
              else (<InclusionFilter>filter).valueList = [result[0][filter.field]];
            } else {
              (<InclusionFilter>filter).valueList = [];
            }
            this.essentialFilters[index]['candidateValues'] = result;
          } else if ('bound' === filter.type) {
            this._setBoundFilter(this.essentialFilters[index], result[0]);
          } else {
            this._setTimeFilter(this.essentialFilters[index], result[0]);
          }

          filters.push(filter);
          index = index + 1;

          if (this.essentialFilters.length <= index) {
            resolve(result);
          } else {
            this._setEssentialFilter(filters, index).then((result) => resolve(result));
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  private _setBoundFilter(filter: Filter, result: any) {
    const boundFilter = <BoundFilter>filter;
    if (result && result.hasOwnProperty('maxValue')) {
      if ((boundFilter.min === 0 && boundFilter.max === 0) || boundFilter.min == null) {
        boundFilter.min = result.minValue;
        boundFilter.max = result.maxValue;
      }
      boundFilter.maxValue = result.maxValue;
      boundFilter.minValue = result.minValue;
    } else {
      boundFilter.min = null;
      boundFilter.max = null;
      boundFilter.maxValue = null;
      boundFilter.minValue = null;
    }
  }

  private _setTimeFilter(filter: Filter, result: any) {
    const rangeFilter: TimeRangeFilter = <TimeRangeFilter>filter;
    if (rangeFilter.intervals == null || rangeFilter.intervals.length === 0) {
      rangeFilter.intervals = [result.minTime + '/' + result.maxTime];
    }
  }

  private _processIngestion(tempDsInfo: { id: string; progressTopic: string }) {
    try {
      const headers: any = {
        'X-AUTH-TOKEN': this.cookieService.get(CookieConstant.KEY.LOGIN_TOKEN),
      };

      const subscription = CommonConstant.stomp.watch(tempDsInfo.progressTopic).subscribe((msg: Message) => {
        const data: { progress: number; message: string } = JSON.parse(msg.body);

        if (-1 === data.progress) {
          this.ingestionStatus = data;
          this.ingestionStatus.step = -1;
          this.alertPrimeService.error(data.message);
          this.safelyDetectChanges();
          subscription.unsubscribe();
        } else if (100 === data.progress) {
          this.ingestionStatus = data;
          this.safelyDetectChanges();
          subscription.unsubscribe();
          this._loadTempDatasourceDetail(tempDsInfo.id);
        } else {
          this.ingestionStatus = data;
          this.ingestionStatus.step = 2;
          this.safelyDetectChanges();
        }
      }, headers);
    } catch (e) {
      console.info(e);
    }
  }

  private _loadTempDatasourceDetail(tempDsId: string) {
    this.dataSourceService
      .getDatasourceDetail(tempDsId)
      .then((ds: Datasource) => {
        setTimeout(() => {
          this.done.emit({
            id: tempDsId,
            info: ds,
            dataSourceId: this._dataSourceId,
            filters: this._ingestResultFilters,
          });
        }, 1000);
        this.ingestionStatus && (this.ingestionStatus.step = 10);
        this.safelyDetectChanges();
      })
      .catch((err) => {
        this.commonExceptionHandler(err, this.translateService.instant('msg.board.alert.fail.ingestion'));
        if (this.ingestionStatus) {
          this.ingestionStatus.progress = -1;
          this.ingestionStatus.step = -1;
        }
      });
  }

  private _setFields(fields: Field[]) {
    let fieldList: Field[] = _.cloneDeep(fields);
    if (!fieldList) {
      fieldList = [];
    }
    this.fields = [];
    this.fields = this.fields
      .concat(fieldList.filter((item) => item.role !== FieldRole.MEASURE))
      .concat(fieldList.filter((item) => item.role === FieldRole.MEASURE));
  }

  private _getField(fieldName: string, ref: string): Field {
    const fields = this.fields;

    let field: Field;

    let idx = -1;
    if (ref) idx = _.findIndex(fields, { ref, name: fieldName });
    else idx = _.findIndex(fields, { name: fieldName });

    if (idx > -1) field = fields[idx];

    return field;
  }

  private _convertServerSpecToUISpec(filters: Filter[]) {
    filters.forEach((filter: Filter) => {
      if (filter.type === 'include') {
        this._convertInclusionSpecToUI(filter);
      }
    });
  }

  private _convertInclusionSpecToUI(filter: Filter): InclusionFilter {
    const includeFilter: InclusionFilter = <InclusionFilter>filter;

    let condition: MeasureInequalityFilter = createMeasureInequalityFilter();
    let limitation: MeasurePositionFilter = createMeasurePositionFilter();
    let wildcard: WildCardFilter = createWildCardFilter();

    includeFilter.preFilters.forEach((preFilter: Filter) => {
      if (preFilter.type === 'measure_inequality') condition = <MeasureInequalityFilter>preFilter;
      else if (preFilter.type === 'measure_position') limitation = <MeasurePositionFilter>preFilter;
      else if (preFilter.type === 'wildcard') wildcard = <WildCardFilter>preFilter;
    });

    this._setUIItem(this.aggregationTypeList, condition, 'aggregationType');
    this._setUIItem(this.aggregationTypeList, limitation, 'aggregationType');
    this._setUIItem(this.conditionTypeList, condition, 'inequality');
    this._setUIItem(this.limitTypeList, limitation, 'position');
    this._setUIItem(this.wildCardTypeList, wildcard, 'contains');

    this.summaryMeasureFields.forEach((item) => {
      if (item.name === condition.field) condition.field = item.name;
      if (item.name === limitation.field) limitation.field = item.name;
    });

    includeFilter.selectionRange = includeFilter.selector.toString().split('_')[0];
    includeFilter.selectionComponent = includeFilter.selector.toString().split('_')[1];

    return includeFilter;
  }

  private _setUIItem(list: any, uiItem: any, key: string) {
    list.forEach((item) => {
      if (item.value === uiItem[key]) {
        uiItem[key + 'UI'] = item;
      }
    });
  }
}
