import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { CommonModule } from '../../../../common/common.module';
import { ColorPickerLayerComponent } from '../color.picker/color.picker.layer.component';
import { ColorPickerLayerModule } from '../color.picker/color.picker.layer.module';
import { RangeSliderComponent } from '../slider/range-slider.component';
import { RangeSliderModule } from '../slider/range-slider.module';

import { AnalysisPredictionComponent } from './analysis-prediction.component';

@NgModule({
  imports: [CommonModule, TranslateModule, RangeSliderModule, ColorPickerLayerModule],
  declarations: [AnalysisPredictionComponent],
  exports: [AnalysisPredictionComponent, ColorPickerLayerComponent, RangeSliderComponent],
})
export class AnalysisPredictionModule {}
