import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SelectionColumnItem } from '@selfai-platform/pipeline-common';
import { DestroyService } from '@selfai-platform/shared';
import { provideDialogService } from '@selfai-platform/shell';
import { Observable } from 'rxjs';
import { SelectionStoreService } from '../cubes/services/selection-store.service';

@Component({
  selector: 'selfai-platform-edit-selection-field',
  templateUrl: './edit-selection-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditSelectionFieldComponent),
      multi: true,
    },
    DestroyService,
    ...provideDialogService(),
  ],
})
export class EditSelectionFieldComponent implements ControlValueAccessor, OnInit {
  value = '';
  disabled = false;
  items$!: Observable<SelectionColumnItem[]>;

  @Input() label = '';

  private onChange!: (_: string) => void;
  private onTouched!: () => void;

  constructor(private readonly cdr: ChangeDetectorRef, private readonly selectionStoreService: SelectionStoreService) {}

  ngOnInit(): void {
    this.items$ = this.selectionStoreService.getSelectionColumns();
  }

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: (_: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  updateValue(value: string): void {
    this.onChange(value);
    this.onTouched();
    this.cdr.markForCheck();
  }
}
