import { KeMqInfo } from '@selfai-platform/pipeline-common';
import { KeMqInfoResponse } from '../models';

const defaultExtractKey = '43801cabeb98fd7ab49db178ed524f6a8ed58814';

export function extractCredentialsForMq(mqInfo: KeMqInfoResponse): KeMqInfo {
  return {
    user: decode(mqInfo.user),
    password: decode(mqInfo.password),
  };
}

function decode(input: string): string {
  const base64DecodedInput = atob(input);
  const xorDecodedInput = XORCipher.encode(defaultExtractKey, base64DecodedInput);

  return hexToString(xorDecodedInput);
}

function hexToString(hexx: string) {
  const hex = hexx.toString(); //force conversion
  let str = '';
  for (let i = 0; i < hex.length; i += 2) str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));

  return str;
}

const XORCipher = {
  encode(key: string, plaintext: string) {
    const bin = xor_encrypt(key, plaintext);
    const hex = Array.from(bin, (b) => b.toString(16).padStart(2, '0')).join('');

    return hex;
  },

  decode(key: string, hexString: string) {
    const hexes = hexString.match(/.{2}/g) as string[];
    const bin = Uint8Array.from(hexes, (byte) => parseInt(byte, 16));

    return xor_decrypt(key, bin);
  },
};

function keyCharAt(key: string, i: number) {
  return key.charCodeAt(Math.floor(i % key.length));
}

function xor_encrypt(key: string, plaintext: string) {
  const bin = new Uint8Array(plaintext.length);
  for (let i = 0; i < plaintext.length; i++) {
    bin[i] = plaintext.charCodeAt(i) ^ keyCharAt(key, i);
  }

  return bin;
}

function xor_decrypt(key: string, bin: Uint8Array) {
  return Array.from(bin, (c, i) => String.fromCharCode(c ^ keyCharAt(key, i))).join('');
}
