import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PipelineVariablesEditResult } from '@selfai-platform/pipeline-common';
import { DialogService } from '@selfai-platform/shell';
import { VariableItemsService } from '../../services/variable-item.service';

@Component({
  selector: 'selfai-platform-pipeline-variables-editor',
  templateUrl: './pipeline-variables-editor.component.html',
  styleUrls: ['./pipeline-variables-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PipelineVariablesEditorComponent {
  constructor(
    private readonly dialogService: DialogService<PipelineVariablesEditResult>,
    private readonly varaibleService: VariableItemsService,
  ) {}

  validationErrors: string | null = null;

  saveVariables() {
    this.validationErrors = this.itemsValidation();
    if (this.validationErrors) {
      return;
    }
    this.dialogService.close({
      needSave: true,
      items: this.varaibleService.value,
    });
  }

  closeEditor() {
    this.dialogService.close({
      needSave: false,
    });
  }

  itemsValidation(): string | null {
    if (!this.varaibleService.value) {
      return null;
    }
    for (const item of this.varaibleService.value) {
      if (!item.key) {
        return 'Key can not be empty';
      }
    }
    const keys = this.varaibleService.value.map((t) => t.key);
    if (new Set(keys).size !== keys.length) {
      return 'Keys must be unique';
    }

    return null;
  }
}
