import { ChangeDetectionStrategy, Component } from '@angular/core';
import { KeRefButtonEditResult } from '@selfai-platform/pipeline-common';
import { DialogService } from '@selfai-platform/shell';
import { KeRefButtonItemsService } from '../../services';

@Component({
  selector: 'selfai-platform-ke-ref-buttons-editor',
  templateUrl: './ke-ref-buttons-editor.component.html',
  styleUrls: ['./ke-ref-buttons-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeRefButtonsEditorComponent {
  constructor(
    private readonly dialogService: DialogService<KeRefButtonEditResult>,
    private readonly keRefButtonsItemsService: KeRefButtonItemsService,
  ) {}

  validationErrors: string | null = null;

  saveVariables() {
    this.validationErrors = this.itemsValidation();
    if (this.validationErrors) {
      return;
    }
    this.dialogService.close({
      needSave: true,
      items: this.keRefButtonsItemsService.value,
    });
  }

  closeEditor() {
    this.dialogService.close({
      needSave: false,
    });
  }

  itemsValidation(): string | null {
    if (!this.keRefButtonsItemsService.value) {
      return null;
    }
    for (const item of this.keRefButtonsItemsService.value) {
      if (!item.title) {
        return 'Key can not be empty';
      }
    }
    const keys = this.keRefButtonsItemsService.value.map((t) => t.title);
    if (new Set(keys).size !== keys.length) {
      return 'Keys must be unique';
    }

    return null;
  }
}
