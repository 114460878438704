import { Injectable } from '@angular/core';
import {
  CubeComment,
  CubeEditResult,
  CubeWorkflowData,
  WorkflowExpressionData,
  WorkflowJsonToColumnTransformationData,
  WorkflowTriggerData,
} from '@selfai-platform/pipeline-common';
import { DialogService } from '@selfai-platform/shell';
import { Observable } from 'rxjs';
import { CmsFormCreatorComponent } from '../components';
import { CodeEditorTransformationComponent } from '../components/dialogs/code-editor-transformation/code-editor-transformation.component';
import { CommentComponent } from '../components/dialogs/comment/comment.component';
import { CreateTriggerComponent } from '../components/dialogs/create-trigger/create-trigger.component';
import { ExpressionToolComponent } from '../components/dialogs/expression-tool/expression-tool.component';
import { JsonToColumnTransformationComponent } from '../components/dialogs/json-to-column-transformation/json-to-column-transformation.component';

@Injectable({
  providedIn: 'root',
})
export class CubeDialogService {
  constructor(private readonly dialogService: DialogService<CubeEditResult, CubeWorkflowData | CubeComment>) {}

  showCreateTrigger(data: CubeWorkflowData<WorkflowTriggerData>): Observable<CubeEditResult> {
    return this.dialogService.showDialog(CreateTriggerComponent, {
      data,
      closeOnEscape: false,
      showHeader: false,
      width: '75%',
      maximizable: true,
    });
  }

  showJsonToColumnTransformation(
    data: CubeWorkflowData<WorkflowJsonToColumnTransformationData>,
  ): Observable<CubeEditResult> {
    return this.dialogService.showDialog(JsonToColumnTransformationComponent, {
      data,
      closeOnEscape: false,
      showHeader: false,
      width: '75%',
      maximizable: true,
    });
  }

  showComment(data: CubeComment): Observable<CubeEditResult> {
    return this.dialogService.showDialog(CommentComponent, {
      header: 'Comment',
      data,
    });
  }

  showExpressionTool(data: CubeWorkflowData<WorkflowExpressionData>): Observable<CubeEditResult> {
    return this.dialogService.showDialog(ExpressionToolComponent, {
      data,
      closeOnEscape: false,
      showHeader: false,
      width: '75%',
      maximizable: true,
    });
  }

  showCodeEditorTransformation(data: CubeWorkflowData): Observable<CubeEditResult> {
    return this.dialogService.showDialog(CodeEditorTransformationComponent, {
      data,
      closeOnEscape: false,
      showHeader: false,
      width: '75%',
      height: '90vh',
      maximizable: true,
    });
  }

  showCmsFormCreator(data: CubeWorkflowData): Observable<CubeEditResult> {
    return this.dialogService.showDialog(CmsFormCreatorComponent, {
      data,
      closeOnEscape: false,
      showHeader: false,
      width: '75%',
      height: '90vh',
      maximizable: true,
    });
  }
}
