export enum PipelinePermission {
  WorkflowList = 'workflows:list',
  WorkflowCreate = 'workflows:create',
  WorkflowUpload = 'workflows:upload',

  WorkflowCloneOwn = 'workflows:clone:own',
  WorkflowDeleteOwn = 'workflows:delete:own',
  WorkflowExecuteOwn = 'workflows:execute:own',
  WorkflowRunOwn = 'workflows:run:own',
  WorkflowGetOwn = 'workflows:get:own',
  WorkflowStopOwn = 'workflows:stop:own',
  WorkflowUpdateOwn = 'workflows:update:own',
  WorkflowExportOwn = 'workflows:export:own',

  WorkflowCloneAny = 'workflows:clone:any',
  WorkflowDeleteAny = 'workflows:delete:any',
  WorkflowExecuteAny = 'workflows:execute:any',
  WorkflowGetAny = 'workflows:get:any',
  WorkflowStopAny = 'workflows:stop:any',
  WorkflowUpdateAny = 'workflows:update:any',
  WorkflowRunAny = 'workflows:run:any',
  WorkflowExportAny = 'workflows:export:any',

  DatasourcesCreateAny = 'datasources:create:any',
  DatasourcesDeleteAny = 'datasources:delete:any',
  DatasourcesUpdateAny = 'datasources:update:any',
  DatasourcesGetAny = 'datasources:get:any',

  DatasourcesDeleteOwn = 'datasources:delete:own',
  DatasourcesUpdateOwn = 'datasources:update:own',
  DatasourcesGetOwn = 'datasources:get:own',
}

export const enum PipelinePermissionEntity {
  Datasources = 'datasources',
  Workflows = 'workflows',
  Workspaces = 'workspaces',
}

export const enum PipelinePermissionScope {
  Own = 'own',
  Any = 'any',
}
