import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Workspace } from '@selfai-platform/bi-domain';
import { BiEntityPermission } from '@selfai-platform/shared';
import { ActionsForItemService, DataListItemAction } from '@selfai-platform/shell';
import { ConfirmationService } from 'primeng/api';
import { map, Observable } from 'rxjs';
import { AggregatorExportService, AggregatorItemService, AggregatorRouteListService } from '../../services';
import { AggregatorRouteDataListViewService, AggregatorRouteListItem } from './aggregator-route-data-list-view.service';

@Injectable()
export class AggregatorRouteActionsForItemService extends ActionsForItemService<AggregatorRouteListItem> {
  constructor(
    private readonly aggregatorRouteDataListViewService: AggregatorRouteDataListViewService,
    private readonly aggregatorExportService: AggregatorExportService,
    private readonly aggregatorItemService: AggregatorItemService,
    private readonly confirmationService: ConfirmationService,
    private readonly aggregatorRouteListService: AggregatorRouteListService,
    private readonly translate: TranslateService,
  ) {
    super();
  }

  override getActionsForItem(aggregatorRoute: AggregatorRouteListItem): Observable<DataListItemAction[]> {
    return this.aggregatorRouteDataListViewService.saving$.pipe(
      map((saving) => {
        const actions: DataListItemAction[] = [
          {
            labelTranslate: 'aggregator-route-list.actions.export-to-file',
            icon: 'pi pi-download',
            action: () => this.aggregatorExportService.exportRoute(aggregatorRoute.uuid),
          },
          {
            labelTranslate: 'aggregator-route-list.actions.clone',
            icon: 'pi pi-copy',
            action: () => this.aggregatorItemService.cloneRoute(aggregatorRoute.uuid),
            disabled: saving,
          },
          {
            labelTranslate: 'aggregator-route-list.actions.remove',
            icon: 'pi pi-trash',
            action: (eventTarget) => this.removeRoute(eventTarget, aggregatorRoute.uuid),
            disabled: saving,
          },
        ];

        return actions;
      }),
    );
  }

  removeRoute(eventTarget: EventTarget, uuid: string): void {
    this.confirmationService.confirm({
      target: eventTarget,
      message: this.translate.instant('aggregator-route-list.actions.remove-confirm'),
      dismissableMask: true,
      closeOnEscape: true,
      acceptLabel: this.translate.instant('aggregator-route-list.actions.remove-confirm.accept-label'),
      rejectLabel: this.translate.instant('aggregator-route-list.actions.remove-confirm.reject-label'),
      acceptButtonStyleClass: 'p-button-success',
      rejectButtonStyleClass: 'p-button-text p-button-link',
      acceptIcon: 'pi pi-trash',
      accept: () => {
        this.aggregatorRouteListService.removeRoute(uuid);
      },
    });
  }

  private hasPermissionForEntity(entity: Workspace, permission: BiEntityPermission): boolean {
    return entity.permissions?.includes(permission);
  }
}
