import { Component, ElementRef, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';

import { DatasourceStatus, Datasource, FieldFormatType, FieldRole } from '@selfai-platform/bi-domain';

import { AbstractComponent } from '../../../common/component/abstract.component';
import { MomentDatePipe } from '../../../common/pipe/moment.date.pipe';
import { Metadata } from '../../../domain/meta-data-management/metadata';
import { MetadataService } from '../../../meta-data-management/metadata/service/metadata.service';
import { DatasourceService } from '../../service/datasource.service';

@Component({
  selector: 'app-datasource-summary',
  templateUrl: './datasource-summary.component.html',
  providers: [MomentDatePipe],
})
export class DatasourceSummaryComponent extends AbstractComponent implements OnInit {
  private datasourceId: string;

  @Input('hasHeader')
  set setHasHeader(hasHeader: boolean) {
    this.hasHeader = hasHeader;
  }

  @Input()
  public showMetadataName = true;

  @Output('close')
  public closeEvent = new EventEmitter();

  public datasource: Datasource;
  public metadata: Metadata;

  public hasHeader = true;
  public isShowDataPreview = false;
  public isEnabled = false;

  constructor(
    private datasourceService: DatasourceService,
    private metadataService: MetadataService,
    protected elementRef: ElementRef,
    protected injector: Injector,
  ) {
    super(elementRef, injector);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  @Input('datasource')
  public set setDatasource(datasourceId: string) {
    if (this.datasourceId !== datasourceId) {
      this.datasourceId = datasourceId;

      this.loadingShow();
      this.datasource = undefined;
      this.metadata = undefined;
      this.datasourceService
        .getDatasourceSummary(this.datasourceId)
        .then((datasource) => {
          this.datasource = datasource;
          this.isEnabled = DatasourceStatus.ENABLED === datasource.status;

          this.datasource.fields.forEach((field, index, object) => {
            if (
              field.role === FieldRole.TIMESTAMP &&
              field.format &&
              field.format.type === FieldFormatType.TEMPORARY_TIME
            ) {
              object.splice(index, 1);
            }
          });

          this.metadataService
            .getMetadataForDataSource(datasource.id)
            .then((result) => {
              if (result && 0 < result.length) {
                this.metadata = result[0];
                this.datasource.uiMetaData = this.metadata;
              }
              this.loadingHide();
              this.changeDetect.detectChanges();
            })
            .catch((err) => this.commonExceptionHandler(err));
        })
        .catch((err) => this.commonExceptionHandler(err));
    }
  }

  public getIconClass(itemType: string): string {
    let result = '';
    switch (itemType.toUpperCase()) {
      case 'TIMESTAMP':
        result = 'ddp-icon-type-calen';
        break;
      case 'BOOLEAN':
        result = 'ddp-icon-type-tf';
        break;
      case 'TEXT':
      case 'DIMENSION':
      case 'STRING':
        result = 'ddp-icon-type-ab';
        break;
      case 'USER_DEFINED':
        result = 'ddp-icon-type-ab';
        break;
      case 'INT':
      case 'INTEGER':
      case 'LONG':
        result = 'ddp-icon-type-int';
        break;
      case 'DOUBLE':
        result = 'ddp-icon-type-float';
        break;
      case 'ARRAY':
        result = 'ddp-icon-type-array';
        break;
      case 'CALCULATED':
        result = 'ddp-icon-type-sharp';
        break;
      case 'LNG':
      case 'LATITUDE':
        result = 'ddp-icon-type-latitude';
        break;
      case 'LNT':
      case 'LONGITUDE':
        result = 'ddp-icon-type-longitude';
        break;
      case 'GEO_POINT':
        result = 'ddp-icon-type-point';
        break;
      case 'GEO_LINE':
        result = 'ddp-icon-type-line';
        break;
      case 'GEO_POLYGON':
        result = 'ddp-icon-type-polygon';
        break;
      default:
        console.error('정의되지 않은 아이콘 타입입니다.', itemType);
        break;
    }
    return result;
  }

  public closeBtn() {
    this.closeEvent.emit(this.datasource);
  }
}
