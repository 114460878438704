import { Injectable } from '@angular/core';
import { TreeNodeType, TreeNodeWorkflow, WorkflowInfo } from '@selfai-platform/pipeline-common';
import { DestroyService } from '@selfai-platform/shared';
import { WorkflowStateService } from '@selfai-platform/storage';
import { BehaviorSubject, filter, map, takeUntil } from 'rxjs';
import { WorkflowListService } from '../../../wokflow';
import { mapWorkflowToTreeNode } from './map-workflow-to-tree-node';

@Injectable()
export class CubeListWorkflowListService extends BehaviorSubject<TreeNodeWorkflow[]> {
  constructor(
    private readonly workflowListService: WorkflowListService,
    private readonly workflowStateService: WorkflowStateService,
    private readonly destroyService$: DestroyService,
  ) {
    super([]);

    this.workflowListService.loadWorkflowList();

    this.workflowListService
      .getWorkflowList()
      .pipe(map(CubeListWorkflowListService.createTreeNodeWorkflow), takeUntil(this.destroyService$))
      .subscribe(this);

    this.workflowStateService
      .getWorkflowState()
      .pipe(filter(Boolean), takeUntil(this.destroyService$))
      .subscribe((workflowState) => {
        const tree = this.value;
        const currentNodeState = tree.find((node) => node.data?.workflowId === workflowState.id);
        const newNodeState = mapWorkflowToTreeNode(workflowState, true);
        if (!currentNodeState) {
          return;
        }
        const currentNodes = currentNodeState.children || [];
        const previousStateNodes = newNodeState.map((node) => {
          const currentNode = currentNodes.find((currentNode) => currentNode.label === node.label);

          return {
            ...node,
            expanded: currentNode ? currentNode.expanded : true,
            leaf: currentNode ? currentNode.leaf : true,
            partialSelected: currentNode ? currentNode.partialSelected : false,
          };
        });

        const treeForUpdate = tree.map((t) => {
          if (t.data?.workflowId === currentNodeState.data?.workflowId) {
            return {
              ...currentNodeState,
              children: previousStateNodes,
            };
          }

          return t;
        });

        this.next(treeForUpdate);
      });
  }

  private static createTreeNodeWorkflow(workflowList: WorkflowInfo[]): TreeNodeWorkflow[] {
    return workflowList.map((workflow) => ({
      label: workflow.name,
      leaf: false,
      type: TreeNodeType.WORKFLOW,
      selectable: false,
      data: { workflowId: workflow.id },
      draggable: false,
    }));
  }
}
