import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ItemUsedInNodes } from '@selfai-platform/pipeline-common';
import { WorkflowEditorFacadeservice } from '../../../../workflow-editor';

@Component({
  selector: 'selfai-platform-used-in-panel',
  templateUrl: './used-in-panel.component.html',
  styleUrls: ['./used-in-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsedInPanelComponent {
  @Input()
  usedInNodes: ItemUsedInNodes[] | undefined = undefined;

  constructor(private readonly workflowEditorService: WorkflowEditorFacadeservice) {}

  navigateToNode(node: ItemUsedInNodes) {
    this.workflowEditorService.openNodeModaldialogUnselectedNode(node.id);
  }
}
