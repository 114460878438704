import { Injectable } from '@angular/core';
import { GraphNodeOptions } from '@selfai-platform/pipeline-common';
import { DialogService } from '@selfai-platform/shell';
import { Observable } from 'rxjs';
import { FavoriteCubeAddComponent } from '../../components/favorite-cube-add/favorite-cube-add.component';

@Injectable({
  providedIn: 'root',
})
export class FavoriteCubeService {
  constructor(private readonly dialogService: DialogService<never, GraphNodeOptions>) {}

  showFavoriteAddForm(data: GraphNodeOptions): Observable<void> {
    return this.dialogService.showDialog(FavoriteCubeAddComponent, {
      data,
      width: '40%',
      header: 'Add to favorites',
    });
  }
}
