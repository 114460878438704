import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { UsedInPanelModule } from '../common/used-in-panel/used-in-panel.module';
import { KeRefButtonsEditorComponent, KeRefButtonsTableComponent } from './components';
import { KeRefButtonItemsService } from './services';
import { DropdownModule } from 'primeng/dropdown';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    FormsModule,
    TableModule,
    TooltipModule,
    InputTextareaModule,
    InputTextModule,
    UsedInPanelModule,
    DropdownModule,
  ],
  declarations: [KeRefButtonsEditorComponent, KeRefButtonsTableComponent],
  exports: [KeRefButtonsEditorComponent],
  providers: [KeRefButtonItemsService],
})
export class KeRefButtonEditorModule {}
