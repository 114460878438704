import { NgModule } from '@angular/core';
import { LoadingService } from '@selfai-platform/shared';

import { AggregatorActiveItemService } from './services/aggregator-active-item.service';
import { AggregatorRouteListService } from './services/aggregator-camel-list.service';
import { AggregatorCamelValidationService } from './services/aggregator-camel-validation.service';
import { AggregatorDictionariesService } from './services/aggregator-dictionaries.service';
import { AggregatorExportService } from './services/aggregator-export.service';
import { AggregatorImportService } from './services/aggregator-import.service';
import { AggregatorItemService } from './services/aggregator-item.service';
import { LOADING_ROUTES_LOAD, LOADING_ROUTES_SAVE } from './tokens';

@NgModule({
  providers: [
    AggregatorActiveItemService,
    AggregatorRouteListService,
    AggregatorItemService,
    AggregatorCamelValidationService,
    AggregatorExportService,
    AggregatorImportService,
    AggregatorDictionariesService,
    {
      provide: LOADING_ROUTES_LOAD,
      useClass: LoadingService,
    },
    {
      provide: LOADING_ROUTES_SAVE,
      useClass: LoadingService,
    },
  ],
})
export class AggregatorCamelModule {}
