import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { UsedInPanelModule } from '../common/used-in-panel/used-in-panel.module';
import { PipelineVariablesEditorComponent } from './components/pipeline-variables-editor/pipeline-variables-editor.component';
import { VariablesTableComponent } from './components/variables-table/variables-table.component';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    FormsModule,
    TableModule,
    TooltipModule,
    InputTextareaModule,
    InputTextModule,
    UsedInPanelModule,
  ],
  declarations: [PipelineVariablesEditorComponent, VariablesTableComponent],
  exports: [PipelineVariablesEditorComponent],
})
export class PipelineVariablesEditorModule {}
