import { Injectable } from '@angular/core';
import {
  VersionsManagerRunResult,
  VersionsManagerWorkflowData,
  WorkflowEditorActions,
  WorkflowVersion,
  WorkflowVersionFull,
} from '@selfai-platform/pipeline-common';
import { DialogService } from '@selfai-platform/shell';
import { AngularJsBridgeService } from '../../angularjs-services/AngularJsBridgeService';
import { VersionsManagerComponent } from '../components/versions-manager.component';

@Injectable()
export class VersionsManagerService {
  private currentWorkflowId: string | undefined;

  constructor(
    private readonly dialogService: DialogService<VersionsManagerRunResult, VersionsManagerWorkflowData>,
    private readonly angularBridgeService: AngularJsBridgeService,
  ) {}

  public showEditor(data: VersionsManagerWorkflowData) {
    this.currentWorkflowId = data.workflowId;

    return this.dialogService.showDialog(VersionsManagerComponent, {
      header: 'Versions manager',
      width: '75%',
      data: data,
    });
  }

  get workflowId() {
    return this.currentWorkflowId;
  }

  public openDialogWithVersion(versionItem: WorkflowVersion) {
    this.angularBridgeService.emitEventToAngularjs('StatusBar.OPEN_DIALOG_WITH_VERSION', versionItem);
  }

  public cloneVersionToWorkflow(versionItem: WorkflowVersionFull) {
    const { content } = versionItem;
    content.thirdPartyData.gui.name = `${content.thirdPartyData.gui.name}_restored_from_v${versionItem.versionNumber}`;
    // TODO: move api call for cloning workflow to separate service of this lib
    this.angularBridgeService.emitEventToAngularjs(WorkflowEditorActions.CloneWorkflow, content);
  }

  public closeDialog() {
    this.dialogService.close();
  }
}
