<div class="ddp-ui-chart-side ddp-show">
  <div class="ddp-ui-side-title">
    {{ 'msg.page.ui.number.format.title' | translate }}
  </div>
  <div class="ddp-wrap-downmenu">
    <div
      format-item
      class="ddp-box-down"
      #commonFormat
      (changeFormat)="onChange($event)"
      [format]="format"
      [uiOption]="uiOption"
    ></div>
  </div>
</div>
