import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { DragulaModule } from 'ng2-dragula';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';

import { BiChartEngineModule, GraphChartComponentModule } from '@selfai-platform/bi-chart-engine';
import {
  BiSharedModule,
  UiSettingsSwitchModule,
  UiSharedSettingsNumberCounterModule,
} from '@selfai-platform/bi-shared';

import { CommonModule } from '../common/common.module';
import { DataPreviewModule } from '../common/data.preview.module';
import { ImageService } from '../common/service/image.service';
import { DashboardShareModule } from '../dashboard/dashboard-share.module';
import { DashboardApiLegacyService } from '../dashboard/service/dashboard-api.service';
import { WidgetService } from '../dashboard/service/widget.service';
import { DatasourceService } from '../datasource/service/datasource.service';
import { MetadataService } from '../meta-data-management/metadata/service/metadata.service';

import { ChartIconComponent } from './chart-icon/chart-icon.component';
import { AxisCategoryOptionComponent } from './chart-style/axis-category-option.component';
import { AxisValueOptionComponent } from './chart-style/axis-value-option.component';
import { CalculatedOptionSliderComponent } from './chart-style/calc-option-slider.component';
import { CalculatedRowOptionComponent } from './chart-style/calc-option.component';
import { ColorOptionCustomDefaultComponent } from './chart-style/color-option/color-option-custom-default/color-option-custom-default.component';
import { ColorOptionComponent } from './chart-style/color-option/color-option.component';
import { CommonOptionComponent } from './chart-style/common-option.component';
import { DataLabelOptionComponent } from './chart-style/datalabel-option.component';
import { FormatItemComponent } from './chart-style/format/format-item.component';
import { FormatOptionComponent } from './chart-style/format-option.component';
import { LegendOptionComponent } from './chart-style/legend-option.component';
import { MapCommonOptionComponent } from './chart-style/map/map-common-option.component';
import { MapFormatOptionComponent } from './chart-style/map/map-format-option.component';
import { MapLayerOptionComponent } from './chart-style/map/map-layer-option.component';
import { MapLegendOptionComponent } from './chart-style/map/map-legend-option.component';
import { MapTooltipOptionComponent } from './chart-style/map/map-tooltip-option.component';
import { SecondaryAxisOptionComponent } from './chart-style/secondary-axis-option.component';
import { SecondaryIndicatorComponent } from './chart-style/secondary-indicator.component';
import { SplitOptionComponent } from './chart-style/split-option.component';
import { TooltipOptionComponent } from './chart-style/tooltip-option.component';
import { XAxisOptionComponent } from './chart-style/xaxis-option.component';
import { YAxisOptionComponent } from './chart-style/yaxis-option.component';
import { AnalysisModule } from './component/analysis/analysis.module';
import { PageAccordionComponent } from './components/page-accordion/page-accordion.component';
import { PageSectionButtonsComponent } from './components/page-section-buttons/page-section-buttons.component';
import { PageSectionChartComponent } from './components/page-section-chart/page-section-chart.component';
import { PageSectionContentComponent } from './components/page-section-content/page-section-content.component';
import { PageSectionDataComponent } from './components/page-section-data/page-section-data.component';
import { PageSectionSideComponent } from './components/page-section-side/page-section-side.component';
import { PageFilterPanelComponent } from './filter/filter-panel.component';
import { PageDataContextComponent } from './page-data/page-data-context.component';
import { PageLoadingComponent } from './page-loading/page-loading.component';
import { MapPagePivotComponent } from './page-pivot/map/map-page-pivot.component';
import { PagePivotComponent } from './page-pivot/page-pivot.component';
import { PivotContextComponent } from './page-pivot/pivot-context.component';
import { PopupValueAliasComponent } from './page-pivot/popup-value-alias.component';
import { PageComponent } from './page.component';
import { YAxisOptionsService } from './service/y-axis-options.service';

@NgModule({
  imports: [
    CommonModule,
    DragulaModule,
    DashboardShareModule,
    DataPreviewModule,
    AnalysisModule,
    UiSettingsSwitchModule,
    UiSharedSettingsNumberCounterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    ButtonModule,
    BiChartEngineModule,
    BiSharedModule,
    GraphChartComponentModule,
    InputTextModule,
  ],
  declarations: [
    PageLoadingComponent,
    PageComponent,
    PagePivotComponent,
    FormatItemComponent,
    PageFilterPanelComponent,
    PageDataContextComponent,
    PopupValueAliasComponent,
    CommonOptionComponent,
    LegendOptionComponent,
    XAxisOptionComponent,
    YAxisOptionComponent,
    SecondaryAxisOptionComponent,
    AxisValueOptionComponent,
    AxisCategoryOptionComponent,
    DataLabelOptionComponent,
    TooltipOptionComponent,
    SecondaryIndicatorComponent,
    FormatOptionComponent,
    ColorOptionComponent,
    SplitOptionComponent,
    CalculatedRowOptionComponent,
    CalculatedOptionSliderComponent,
    MapPagePivotComponent,
    MapCommonOptionComponent,
    MapLayerOptionComponent,
    MapLegendOptionComponent,
    MapTooltipOptionComponent,
    MapFormatOptionComponent,
    PivotContextComponent,
    ChartIconComponent,
    ColorOptionCustomDefaultComponent,
    PageSectionDataComponent,
    PageSectionChartComponent,
    PageSectionContentComponent,
    PageSectionButtonsComponent,
    PageSectionSideComponent,
    PageAccordionComponent,
  ],
  exports: [
    PageLoadingComponent,
    PageComponent,
    PagePivotComponent,
    FormatItemComponent,
    PageFilterPanelComponent,
    PageDataContextComponent,
    PopupValueAliasComponent,
    CommonOptionComponent,
    LegendOptionComponent,
    XAxisOptionComponent,
    YAxisOptionComponent,
    SecondaryAxisOptionComponent,
    AxisValueOptionComponent,
    AxisCategoryOptionComponent,
    DataLabelOptionComponent,
    TooltipOptionComponent,
    SecondaryIndicatorComponent,
    FormatOptionComponent,
    ColorOptionComponent,
    SplitOptionComponent,
    CalculatedRowOptionComponent,
    CalculatedOptionSliderComponent,
    MapPagePivotComponent,
    MapCommonOptionComponent,
    MapLayerOptionComponent,
    MapLegendOptionComponent,
    MapTooltipOptionComponent,
    MapFormatOptionComponent,
    PivotContextComponent,
    ChartIconComponent,
  ],
  providers: [
    DatasourceService,
    DashboardApiLegacyService,
    MetadataService,
    WidgetService,
    ImageService,
    YAxisOptionsService,
  ],
})
export class PageShareModule {}
