import { Injectable, OnDestroy } from '@angular/core';
import { GraphNodeOptionSerialized, WorkflowEditorActions } from '@selfai-platform/pipeline-common';
import { AlertService } from '@selfai-platform/shared';
import { filter, from, fromEvent, Observable, Subscription, switchMap, throttleTime } from 'rxjs';
import { WorkflowEditorElementService } from '../../workflow-editor/workflow-editor-element.services';
import { WorkflowEditorFacadeservice } from '../../workflow-editor/workflow-editor-facade.service';

const PREFIX_CLIPBOARD_NODE = 'copyCube:';

@Injectable()
export class CopyPasteCubeService implements OnDestroy {
  cursorPosition: { x: number; y: number } = { x: 0, y: 0 };

  subscription = new Subscription();

  constructor(
    workflowEditorElemnentService: WorkflowEditorElementService,
    private readonly workflowEditorFacadeService: WorkflowEditorFacadeservice,
    private readonly alertService: AlertService,
  ) {
    this.subscription.add(
      workflowEditorElemnentService.pasteCubeElement$
        .pipe(
          filter(Boolean),
          switchMap((pasteCubeElement) => {
            return fromEvent(pasteCubeElement, 'mousemove');
          }),
          throttleTime(300),
        )
        .subscribe((e) => {
          const { clientX: x, clientY: y } = e as MouseEvent;
          this.cursorPosition = { x, y };
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  copyToClipboard(cube: GraphNodeOptionSerialized): Observable<void> {
    return from(window.navigator.clipboard.writeText(`${PREFIX_CLIPBOARD_NODE}${JSON.stringify(cube)}`));
  }

  pasteFromClipboard(event: ClipboardEvent): void {
    const searializedData = event.clipboardData?.getData('text/plain');
    if (!searializedData || !this.isCubeData(searializedData)) {
      return;
    }

    const dataString = searializedData.replace(PREFIX_CLIPBOARD_NODE, '');
    try {
      const cubeData = JSON.parse(dataString) as GraphNodeOptionSerialized;
      this.workflowEditorFacadeService.addNode(cubeData, this.cursorPosition);
    } catch (err) {
      console.error(err);
      this.alertService.error('Error while pasting cube');
    }
  }

  isCubeData(searializedData: string): boolean {
    return searializedData.indexOf(PREFIX_CLIPBOARD_NODE) === 0;
  }
}
