import { Injectable } from '@angular/core';
import {
  GraphNodeOptionSerialized,
  TreeNodeCube,
  TreeNodeRemovedCube,
  TreeNodeType,
} from '@selfai-platform/pipeline-common';
import { AlertService } from '@selfai-platform/shared';
import { MenuItem, ConfirmationService } from 'primeng/api';

import { WorkflowEditorFacadeservice } from '../../../workflow-editor/workflow-editor-facade.service';
import { CopyPasteCubeService } from '../../services';
import { CubeListRenameCubeService } from './cube-list-rename-cube.service';

@Injectable()
export class CubeListContextMenuService {
  constructor(
    private readonly copyPasteCubeService: CopyPasteCubeService,
    private readonly alertService: AlertService,
    private readonly workflowEditorFacadeService: WorkflowEditorFacadeservice,
    private readonly cubeListRenameCubeService: CubeListRenameCubeService,
    private readonly confirmationService: ConfirmationService,
  ) {}

  getContextMenuActions(node: TreeNodeCube | TreeNodeRemovedCube, $event: MouseEvent): MenuItem[] {
    const { data } = node;

    if (data && data.isInCurrentWorkflow) {
      if (node.type === TreeNodeType.REMOVEDCUBE) {
        return [
          {
            label: 'Restore',
            icon: 'pi pi-replay',
            command: () => {
              this.restoreCube(node);
            },
          },
          {
            label: 'Remove',
            icon: 'pi pi-trash',
            command: () => {
              const target = $event.target as EventTarget;
              this.removeCubesFromRecyclebin(target, node);
            },
          },
          {
            label: 'Copy to clipboard',
            icon: 'pi pi-copy',
            command: () => {
              this.copyToClipboard(data.node);
            },
          },
        ];
      }

      if (node.type === TreeNodeType.CUBE) {
        return [
          {
            label: 'Rename',
            icon: 'pi pi-pencil',
            command: () => {
              this.cubeListRenameCubeService.startRenameCube(node);
            },
          },
          {
            label: 'Open in editor',
            icon: 'pi pi-eye',
            command: () => {
              this.openInEditor(data.node);
            },
          },
          {
            label: 'Copy to clipboard',
            icon: 'pi pi-copy',
            command: () => {
              this.copyToClipboard(data.node);
            },
          },
        ];
      }
    }

    return [];
  }

  copyToClipboard(cube: GraphNodeOptionSerialized): void {
    this.copyPasteCubeService.copyToClipboard(cube).subscribe({
      complete: () => {
        this.alertService.success('Node copied to clipboard');
      },
    });
  }

  private restoreCube(node: TreeNodeRemovedCube) {
    const nodeId = node.data?.node.id;
    if (nodeId) {
      this.workflowEditorFacadeService.restoreNodes([nodeId]);
    }
  }

  removeCubesFromRecyclebin(target: EventTarget, node: TreeNodeRemovedCube) {
    this.confirmationService.confirm({
      target,
      message: 'You are going to remove cube. Are you sure?',
      dismissableMask: true,
      closeOnEscape: true,
      acceptLabel: 'Remove',
      rejectLabel: 'Cancel',
      acceptButtonStyleClass: 'p-button-success',
      rejectButtonStyleClass: 'p-button-text p-button-link',
      acceptIcon: 'pi pi-trash',
      accept: () => {
        const nodeId = node.data?.node.id;
        if (nodeId) {
          this.workflowEditorFacadeService.removeNodesFromRecycleBin([nodeId]);
        }
      },
    });
  }

  private openInEditor(node: GraphNodeOptionSerialized): void {
    this.workflowEditorFacadeService.openNodeModaldialogSelectedNode(node.id);
  }
}
