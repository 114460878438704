import { HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IIdentityProvider } from './identity-provider.interface';
import { ProviderUserProfile } from './provider-user-profile';
import { LoginOptions } from './models/login-options';

export abstract class IdentityProviderAbstractService implements IIdentityProvider {
  abstract login(options?: unknown): Promise<void>;
  abstract logout(redirectUri?: string): Promise<void>;
  abstract init(options?: unknown): Promise<boolean>;
  abstract shouldAddToken: (request: HttpRequest<unknown>) => boolean;
  abstract shouldUpdateToken: (request: HttpRequest<unknown>) => boolean;
  abstract register(options?: LoginOptions): Promise<void>;
  abstract isUserInRole(role: string, resource?: string): boolean;
  abstract getUserRoles(allRoles?: boolean): string[];
  abstract isLoggedIn(): Promise<boolean>;
  abstract isTokenExpired(minValidity?: number): boolean;
  abstract updateToken(minValidity?: number): Promise<boolean>;
  abstract loadUserProfile(forceReload?: boolean): Promise<ProviderUserProfile>;
  abstract getToken(): Promise<string>;
  abstract getUsername(): string;
  abstract clearToken(): void;
  abstract addTokenToHeader(headers?: HttpHeaders): Observable<HttpHeaders>;
}
