import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AgGridModule } from 'ag-grid-angular';
import { BarChartComponent } from './bar-chart.component';
import { BoxPlotChartComponent } from './boxplot-chart.component';
import { CombineChartComponent } from './combine-chart.component';
import { GaugeChartComponent } from './gauge-chart.component';
import { GraphChartLegacyComponent } from './graph-chart.component';
import { GridChartComponent } from './grid-chart.component';
import { HeatMapChartComponent } from './heatmap-chart.component';
import { InputGridChartComponent } from './input-grid-chart.component';
import { LabelChartComponent } from './label-chart.component';
import { LineChartComponent } from './line-chart.component';
import { PieChartComponent } from './pie-chart.component';
import { RadarChartComponent } from './radar-chart.component';
import { SankeyChartComponent } from './sankey-chart.component';
import { ScatterChartComponent } from './scatter-chart.component';
import { TreeMapChartComponent } from './treemap-chart.component';
import { WaterFallChartComponent } from './waterfall-chart.component';
import { WordCloudChartComponent } from './wordcloud-chart.component';

const components = [
  BarChartComponent,
  BoxPlotChartComponent,
  CombineChartComponent,
  GaugeChartComponent,
  GraphChartLegacyComponent,
  GridChartComponent,
  HeatMapChartComponent,
  InputGridChartComponent,
  LabelChartComponent,
  LineChartComponent,
  PieChartComponent,
  RadarChartComponent,
  SankeyChartComponent,
  TreeMapChartComponent,
  ScatterChartComponent,
  WaterFallChartComponent,
  WordCloudChartComponent,
];

@NgModule({
  imports: [CommonModule, TranslateModule, AgGridModule],
  declarations: components,
  exports: components,
  providers: [],
})
export class ChartsComponentModule {}
