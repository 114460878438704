import { ChangeDetectionStrategy, Component, Inject, Input, OnInit, Renderer2 } from '@angular/core';

import {
  DestroyService,
  SelfaiAuthService,
  KdBuildVersionService,
  KeBuildVersionService,
  PACKAGE_VERSION,
  SELFAI_APP_BASE_HREF,
  ProviderUserProfile,
} from '@selfai-platform/shared';
import { MenuItem } from 'primeng/api';
import { Observable, from, take, takeUntil } from 'rxjs';
import { MenuService } from '../menu';

@Component({
  selector: 'selfai-platform-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class HeaderComponent implements OnInit {
  rotateMenuButton$ = this.menuService.isOpened$;
  topbarMenuActive!: boolean;
  activeMenuItem$ = this.menuService.activeMenuItem$;
  logoPathWithBaseHref!: string;
  userProfile$!: Observable<ProviderUserProfile>;

  readonly topbarTheme = 'light';
  readonly menuTheme = 'light';
  readonly menuMode = 'static';
  readonly defaultLogoPath = 'assets/images/logotype_selfai.png';

  @Input() tabs!: MenuItem[];
  @Input() logoPath!: string;

  constructor(
    @Inject(SELFAI_APP_BASE_HREF) private readonly baseHref: string,
    @Inject(PACKAGE_VERSION) public readonly packageVersion: string,
    public readonly biVersion$: KdBuildVersionService,
    public readonly pipelineVersion$: KeBuildVersionService,
    public readonly renderer: Renderer2,
    private readonly menuService: MenuService,
    private readonly selfaiAuthService: SelfaiAuthService,
    private readonly destroy$: DestroyService,
  ) {}

  ngOnInit(): void {
    if (!this.logoPath) {
      this.logoPath = this.defaultLogoPath;
    }
    this.logoPathWithBaseHref = this.baseHref + this.logoPath;

    this.userProfile$ = from(this.selfaiAuthService.getProvider().loadUserProfile());
  }

  onMenuButtonClick(event: Event): void {
    event.preventDefault();
    this.menuService.toggleMenu();
  }

  onTopbarItemClick(event: Event, item: HTMLElement): void {
    this.menuService.activeMenuItem$.pipe(take(1), takeUntil(this.destroy$)).subscribe((activeTopbarItem) => {
      if (activeTopbarItem === item) {
        this.menuService.clearActiveMenuItem();
      } else {
        this.menuService.setActiveMenuItem(item);
      }
    });

    event.preventDefault();
  }

  isTablet() {
    const width = window.innerWidth;

    return width <= 1024 && width > 640;
  }

  isDesktop() {
    return window.innerWidth > 992;
  }

  isMobile() {
    return window.innerWidth <= 640;
  }

  async logout() {
    await this.selfaiAuthService.getProvider().logout(window.location.href);
  }
}
