import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DateValueFieldComponentModule } from '../aggregator-camel/components/fields/date-value-field/date-value-field.module';
import { TypeFieldSwitchComponentModule } from '../aggregator-camel/components/fields/type-field-switch/type-field-switch.module';
import { CodeEditorComponentModule } from '../code-editor/code-editor.module';
import { EditSelectionFieldComponentModule } from '../edit-selection-field/edit-selection-field.module';
import { FormFieldComponentModule } from '../form-field/form-field.module';
import { ConditionEditComponent } from './condition-edit.component';

@NgModule({
  imports: [
    CommonModule,
    InputTextModule,
    InputNumberModule,
    CheckboxModule,
    ReactiveFormsModule,
    DropdownModule,
    TypeFieldSwitchComponentModule,
    DateValueFieldComponentModule,
    FormFieldComponentModule,
    CardModule,
    EditSelectionFieldComponentModule,
    InputTextareaModule,
    CodeEditorComponentModule,
  ],
  declarations: [ConditionEditComponent],
  exports: [ConditionEditComponent],
})
export class ConditionEditComponentModule {}
