import { Injectable, OnDestroy } from '@angular/core';
import { ItemUsedInNodes, WorkflowSerialized } from '@selfai-platform/pipeline-common';
import { WorkflowStateService } from '@selfai-platform/storage';
import { BehaviorSubject, combineLatest, filter, map } from 'rxjs';
import { jsonToValues } from '../../utils';
import { VariableItemsService } from './variable-item.service';

@Injectable()
export class VariablesUsedInWorkflowService
  extends BehaviorSubject<Map<string, ItemUsedInNodes[]>>
  implements OnDestroy
{

  get usedInMap$() {
    return this.asObservable();
  }

  constructor(private readonly workflowStateService: WorkflowStateService,
    private readonly varaiblesservices: VariableItemsService) {
    super(new Map());

    combineLatest([this.workflowStateService.getWorkflowState(), this.varaiblesservices])
      .pipe(
        filter(([workflow, varaibles]) => !!workflow && !!varaibles)
      )
      .subscribe((data) => {
        const keys = data[1].map(t => t.key);
        this.calculateVaraiblesMap(data[0] as WorkflowSerialized, keys);
      });
  }

  private calculateVaraiblesMap(workflow: WorkflowSerialized, variableKeys: string[]): void {
    const columnsMap = new Map<string, ItemUsedInNodes[]>();
    const cubesForSearch = workflow.workflow.nodes
      .map((cube) => {
        const result = jsonToValues(cube.parameters);

        return {
          id: cube.id,
          name: workflow.thirdPartyData.gui.nodes[cube.id]?.uiName || cube.operation?.name || '',
          searchText: result,
        };
      });
    for (const variablekey of variableKeys) {
      const usedIn: ItemUsedInNodes[] = [];
      cubesForSearch.forEach((cube) => {
        for (let i = 0; i < cube.searchText.length; i++) {
          if (cube.searchText[i].includes(`\${${variablekey}}`)) {
            usedIn.push({
              id: cube.id,
              title: cube.name,
            });

            return;
          }
        }
      });

      if (usedIn.length > 0) {
        columnsMap.set(variablekey, usedIn);
      }
    }

    this.next(columnsMap);
  }

  ngOnDestroy(): void {
    this.complete();
  }
}
