
import { Component, ElementRef, Injector, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';

import * as _ from 'lodash';

import {
  Candidate,
  DIRECTION,
  DatasourceField as Field,
  Filter,
  FilterWidget,
  InclusionFilter,
  InclusionSelectorType,
  InclusionSortBy,
  createCandidate,
} from '@selfai-platform/bi-domain';
import { isNullOrUndefined } from '@selfai-platform/shared';

import { SubscribeArg } from '../../../common/domain/subscribe-arg';
import { EventBroadcaster } from '../../../common/event/event.broadcaster';
import { PopupService } from '../../../common/service/popup.service';
import { DatasourceService } from '../../../datasource/service/datasource.service';
import { FilterUtil } from '../../util/filter.util';
import { AbstractFilterPanelComponent } from '../abstract-filter-panel.component';

@Component({
  selector: 'inclusion-filter-panel',
  templateUrl: './inclusion-filter-panel.component.html',
  styleUrls: ['./inclusion-filter-panel.component.scss'],
})
export class InclusionFilterPanelComponent
  extends AbstractFilterPanelComponent
  implements OnInit, OnChanges, OnDestroy
{
  @ViewChild('inputSearch')
  private _inputSearch: ElementRef;

  @Input('filter')
  public originalFilter: InclusionFilter;

  public currentPage = 1;
  public filter: InclusionFilter;
  public isDeSelected = false;
  public isMultiSelector = false;
  public isNewFilter = false;
  public isOverCandidateWarning = false;
  public isSearchFocus = false;
  public lastPage = 1;
  public pageCandidateList: Candidate[] = [];
  public pageSize = 10;
  public popupVisible = false;
  public searchAllMessage = '';
  public searchText = '';
  public sortBy = InclusionSortBy;
  public sortDirection = DIRECTION;
  public totalCount = 0;

  private candidateList: Candidate[] = [];

  constructor(
    private broadCaster: EventBroadcaster,
    private popupService: PopupService,
    protected datasourceService: DatasourceService,
    protected elementRef: ElementRef,
    protected injector: Injector,
  ) {
    super(elementRef, injector);
  }

  public get candidateListSize(): number {
    return this.candidateList.length;
  }

  public get isSingleSelector(): boolean {
    return (
      InclusionSelectorType.SINGLE_LIST === this.filter.selector ||
      InclusionSelectorType.SINGLE_COMBO === this.filter.selector
    );
  }

  public get isListSelector(): boolean {
    return (
      InclusionSelectorType.SINGLE_LIST === this.filter.selector ||
      InclusionSelectorType.MULTI_LIST === this.filter.selector
    );
  }

  public get currentSortOptLabel(): string {
    if (this.sortBy.TEXT === this.filter.sort.by) {
      if (this.sortDirection.ASC === this.filter.sort.direction) {
        return this.translateService.instant('msg.comm.ui.soring.alphnumeric.asc');
      } else if (this.sortDirection.DESC === this.filter.sort.direction) {
        return this.translateService.instant('msg.comm.ui.soring.alphnumeric.desc');
      }
    } else if (this.sortBy.COUNT === this.filter.sort.by) {
      if (this.sortDirection.ASC === this.filter.sort.direction) {
        return this.translateService.instant('msg.comm.ui.soring.frequency.asc');
      } else if (this.sortDirection.DESC === this.filter.sort.direction) {
        return this.translateService.instant('msg.comm.ui.soring.frequency.desc');
      }
    }
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngAfterViewInit() {
    super.ngAfterViewInit();

    this.initComponent(this.originalFilter);

    if (this.originalFilter['isNew']) {
      this.isNewFilter = true;
      this.safelyDetectChanges();
      delete this.originalFilter['isNew'];
      setTimeout(() => {
        this.isNewFilter = false;
        this.safelyDetectChanges();
      }, 1500);
    }

    this.subscriptions.push(
      this.broadCaster.on<any>('CHANGE_FILTER_SELECTOR').subscribe((data) => {
        this.filter.selector = (<FilterWidget>data.widget).configuration.filter.selector as InclusionSelectorType;
      }),
    );

    const popupSubscribe = this.popupService.filterView$.subscribe((data: SubscribeArg) => {
      if (data.type === 'page' && this.isDashboardMode) return;

      if (
        'change-filter' === data.name &&
        this.filter.field === data.data.field &&
        this.filter.dataSource === data.data.dataSource
      ) {
        this.initComponent(data.data);
      } else if ('remove-filter' === data.name && this.filter.ui.importanceType === 'general') {
        this.resetList(data.data);
      } else if ('reset-general-filter' === data.name && this.filter.ui.importanceType === 'general') {
        this.filter.valueList = [];
        this.candidate();
      } else if ('change-recommended-filter-value' === data.name && this.filter.ui.importanceType === 'recommended') {
        const filter: Filter = data.data;

        if (filter.ui.filteringSeq < this.filter.ui.filteringSeq) {
          this.resetList(filter);
        }
      }
    });
    this.subscriptions.push(popupSubscribe);
    this.changeDetect.detectChanges();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public inactiveSearchInput() {
    const inputElm = this._inputSearch.nativeElement;
    '' === inputElm.value.trim() && (this.isSearchFocus = false);
    inputElm.blur();
  }

  public checkAll() {
    if (this.isMultiSelector) {
      this.filter.valueList = [];
      this.candidateList.forEach((item) => this.filter.valueList.push(item.name));
    } else {
      this.filter.valueList = [];
    }

    this.filter.valueList.forEach((item) => {
      if (-1 === this.filter.candidateValues.indexOf(item)) {
        this.filter.candidateValues.push(item);
      }
    });

    this.isDeSelected = false;
    this.updateFilterEvent.emit(this.filter);
  }

  public onSelected(item: string, event?: MouseEvent) {
    if (this.isMultiSelector) {
      const checked = event.target ? event.target['checked'] : event.currentTarget['checked'];
      if (checked) {
        this.filter.valueList.push(item);
      } else {
        const idx = this.filter.valueList.indexOf(item);
        this.filter.valueList.splice(idx, 1);
      }
    } else {
      this.filter.valueList = [];
      this.filter.valueList.push(item);
    }

    this.filter.valueList.forEach((item) => {
      if (-1 === this.filter.candidateValues.indexOf(item)) {
        this.filter.candidateValues.push(item);
      }
    });

    this.isDeSelected = false;
    this.updateFilterEvent.emit(this.filter);
  }

  public addDefineValues() {
    if (this.filter.definedValues && this.filter.definedValues.length > 0) {
      this.candidateList = this.filter.definedValues
        .map((item) => this.stringToCandidate(item))
        .concat(this.candidateList);
    }
  }

  public resetFilter(filter: InclusionFilter) {
    this.filter = _.cloneDeep(this.originalFilter);
    this.safelyDetectChanges();
    this.updateFilterEvent.emit(this.filter);
  }

  public toggleDetailMenu() {
    this.isShowDetailMenu = !this.isShowDetailMenu;
  }

  public sortCandidateValues(filter: InclusionFilter, sortBy?: InclusionSortBy, direction?: DIRECTION) {
    sortBy && (filter.sort.by = sortBy);
    direction && (filter.sort.direction = direction);

    const allCandidates: Candidate[] = _.cloneDeep(this.candidateList);
    if (InclusionSortBy.COUNT === filter.sort.by) {
      allCandidates.sort((val1: Candidate, val2: Candidate) => {
        return DIRECTION.ASC === filter.sort.direction ? val1.count - val2.count : val2.count - val1.count;
      });
    } else {
      allCandidates.sort((val1: Candidate, val2: Candidate) => {
        const name1: string = val1.name ? val1.name.toUpperCase() : '';
        const name2: string = val2.name ? val2.name.toUpperCase() : '';
        if (name1 < name2) {
          return DIRECTION.ASC === filter.sort.direction ? -1 : 1;
        }
        if (name1 > name2) {
          return DIRECTION.ASC === filter.sort.direction ? 1 : -1;
        }
        return 0;
      });
    }
    this.candidateList = allCandidates;

    this.setCandidatePage(1, true);

    this.safelyDetectChanges();
  }

  public setCandidatePage(page: number, isInitial: boolean = false) {
    if (this.searchText === '') {
      this.searchAllMessage = '';
    } else {
      this.searchAllMessage = this.translateService.instant('msg.board.filter.ui.search-all');
    }

    if (isInitial) {
      this.pageCandidateList = [];
      this.currentPage = 1;
      this.lastPage = 1;
      this.totalCount = 0;
    }

    if (page <= 0) return;
    if (this.lastPage < page) return;

    this.currentPage = page;
    let start = 0;
    let end = 0;

    if (this.candidateList && 0 < this.candidateList.length) {
      let pagedList: Candidate[] = _.cloneDeep(this.candidateList);

      if (this.filter.showSelectedItem) {
        pagedList = pagedList.filter((item) => {
          return -1 < this.filter.valueList.findIndex((val) => val === item.name);
        });
      }

      if ('' !== this.searchText) {
        pagedList = pagedList.filter((item) => {
          return item.name ? -1 < item.name.toLowerCase().indexOf(this.searchText.toLowerCase()) : false;
        });
      }

      this.totalCount = pagedList.length;

      this.lastPage =
        this.totalCount % this.pageSize === 0
          ? this.totalCount / this.pageSize
          : Math.floor(this.totalCount / this.pageSize) + 1;
      1 > this.lastPage && (this.lastPage = 1);

      start = page * this.pageSize - this.pageSize;
      end = page * this.pageSize;
      if (end > this.totalCount) {
        end = this.totalCount;
      }

      this.pageCandidateList = pagedList.slice(start, end);
    }
  }

  public deleteFilter(filter: Filter) {
    this.deleteFilterEvent.emit(filter);
  }

  public editFilterByPopup(filter: Filter) {
    this.openUpdateFilterPopup(filter);
  }

  public candidateFromSearchText() {
    this.isSearchFocus = false;
    this.candidate(false);
  }

  public deselectAll() {
    this.filter.valueList = [];
    this.isDeSelected = true;
    this.updateFilterEvent.emit(this.filter);
  }

  public setSelectorType(type: string) {
    if ('SINGLE' === type) {
      this.isMultiSelector = false;
      if (this.isListSelector) {
        this.filter.selector = InclusionSelectorType.SINGLE_LIST;
        this.originalFilter.selector = InclusionSelectorType.SINGLE_LIST;
      } else {
        this.filter.selector = InclusionSelectorType.SINGLE_COMBO;
        this.originalFilter.selector = InclusionSelectorType.SINGLE_COMBO;
      }
      if (1 < this.filter.valueList.length) {
        this.filter.valueList = [this.filter.valueList[0]];
        this.originalFilter.valueList = [this.originalFilter.valueList[0]];
      }
    } else {
      this.isMultiSelector = true;
      if (this.isListSelector) {
        this.filter.selector = InclusionSelectorType.MULTI_LIST;
        this.originalFilter.selector = InclusionSelectorType.MULTI_LIST;
      } else {
        this.filter.selector = InclusionSelectorType.MULTI_COMBO;
        this.originalFilter.selector = InclusionSelectorType.MULTI_COMBO;
      }
    }
    this.updateFilterEvent.emit(this.filter);
    this.safelyDetectChanges();
  }

  private initComponent(filter: InclusionFilter) {
    const currFilter: InclusionFilter = _.cloneDeep(filter);

    if (currFilter.valueList && 1 < currFilter.valueList.length) {
      this.isMultiSelector = true;
    } else {
      this.isMultiSelector =
        currFilter.selector === InclusionSelectorType.MULTI_COMBO ||
        currFilter.selector === InclusionSelectorType.MULTI_LIST;
    }

    this.filter = currFilter;

    this.setPanelData(currFilter);

    this.dataSource && this.candidate();
  }

  private resetList(filter: Filter) {
    if (filter.field !== this.filter.field) {
      if (filter.ui.widgetId && filter.ui.widgetId === this.filter.ui.widgetId) {
        this.candidate();
      } else if (!filter.ui.widgetId) {
        this.candidate();
      }
    }
  }

  private candidate(isInit: boolean = true) {
    if (this.filter && this.dashboard && this.field) {
      this.loadingShow();

      this.datasourceService
        .getCandidateForFilter(this.filter, this.dashboard, [], this.field, 'COUNT', this.searchText)
        .then((result) => {
          this.candidateList = [];

          this.addDefineValues();

          const selectedCandidateValues: string[] = this.filter.candidateValues;

          if (selectedCandidateValues && 0 < selectedCandidateValues.length) {
            selectedCandidateValues.forEach((selectedItem) => {
              const item = result.find((item) => item.field === selectedItem);
              if (item) {
                this.candidateList.push(this.objToCandidate(item, this.field));
              } else {
                this.candidateList.push(this.stringToCandidate(selectedItem));
              }
            });

            if (this.searchText) {
              result.forEach((searchItem) => {
                const item = this.candidateList.find((item) => item.name === searchItem.field);
                if (isNullOrUndefined(item)) {
                  this.candidateList.push(this.objToCandidate(searchItem, this.field));
                }
              });
            }
          } else {
            this.filter.candidateValues = [];
            result.forEach((item) => {
              this.filter.candidateValues.push(item.field);
              this.candidateList.push(this.objToCandidate(item, this.field));
            });
          }

          if (isInit) {
            this.isOverCandidateWarning =
              FilterUtil.CANDIDATE_LIMIT <= result.length || result.length > this.candidateListSize;
          }

          this.sortCandidateValues(this.filter);

          this.isShowFilter = true;

          if (result == null || result.length == 0) {
            this.searchAllMessage = this.translateService.instant('msg.board.filter.ui.search-all.nodata');
          } else {
            this.searchAllMessage = '';
          }

          this.loadingHide();
        })
        .catch((error) => {
          this.commonExceptionHandler(error);

          this.candidateList = [];

          this.isShowFilter = true;
        });
    }
  }

  private objToCandidate(item: { field?: string; count: number }, field: Field): Candidate {
    return createCandidate({
      count: item.count,
      name: 'field' in item ? item.field : item[field.name],
    });
  }

  private stringToCandidate(item: string, isDefine: boolean = false): Candidate {
    const candidate = createCandidate({
      name: item,
      count: 0,
      isDefinedValue: isDefine,
    });
    return candidate;
  }
}
