import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory, EntityDataService } from '@ngrx/data';
import { AGGREGATOR_PROCESS_LIST_ENTITY_NAME } from '../const';
import { AggregatorProcessListItem } from '../models/aggregator-process.model';
import { AggregatorProcessListDataService } from '../services/aggregator-process-list-data.service';

@Injectable({ providedIn: 'root' })
export class AggregatorProcessListStore extends EntityCollectionServiceBase<AggregatorProcessListItem> {
  constructor(
    entityDataService: EntityDataService,
    dataService: AggregatorProcessListDataService,
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
  ) {
    entityDataService.registerService(AGGREGATOR_PROCESS_LIST_ENTITY_NAME, dataService);
    super(AGGREGATOR_PROCESS_LIST_ENTITY_NAME, serviceElementsFactory);
  }
}
