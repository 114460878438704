import { Inject, Injectable } from '@angular/core';
import { DatasourceTypes, IAngularJsPipelineOptions, InitLegacyPipelineParams } from '@selfai-platform/pipeline-common';
import {
  DestroyService,
  SelfaiAuthService,
  PipelineConfigService,
  ScriptNames,
  ScriptService,
  SELFAI_APP_BASE_HREF,
} from '@selfai-platform/shared';
import { EditorStateService, WorkflowStateService } from '@selfai-platform/storage';
import * as Ng1 from 'angular';
import { combineLatest, combineLatestWith, filter, from, switchMap, takeUntil } from 'rxjs';
import { DatasourcePermissionService, WorkflowPermissionService } from '../wokflow';
import { AngularJsBridgeService } from './AngularJsBridgeService';
import { KE_AVALIABLE_DATASOURCES } from '../common';
import { KeMqInfoService } from '../mq/services/ke-mq-info.service';

declare global {
  interface Window {
    initLegacyPipeline: (params: InitLegacyPipelineParams) => Promise<ng.auto.IInjectorService>;
  }
}

@Injectable({ providedIn: 'root' })
export class AngularJsLoaderService {
  readonly defualtSelectorForBootstrap = '#ppl-ng-app';

  app: Ng1.auto.IInjectorService | undefined;

  constructor(
    private readonly scriptService: ScriptService,
    private readonly destroy$: DestroyService,
    private readonly angularBridge: AngularJsBridgeService,
    private readonly pipelineConfigService: PipelineConfigService,
    private readonly selfaiAuthService: SelfaiAuthService,
    private readonly workflowStateService: WorkflowStateService,
    private readonly workflowPermissionService: WorkflowPermissionService,
    private readonly editoreStateService: EditorStateService,
    private readonly datasourcePermissionService: DatasourcePermissionService,
    private readonly mqInfoService: KeMqInfoService,
    @Inject(SELFAI_APP_BASE_HREF) private readonly baseHref: string,
    @Inject(KE_AVALIABLE_DATASOURCES) private readonly avaliableDatasources: DatasourceTypes[],
  ) {}

  public bootstrapPipeline(options: IAngularJsPipelineOptions, onBootstrapEnd?: { (): void }) {
    const optionsWithSelector: IAngularJsPipelineOptions = {
      ...options,
      baseHref: this.baseHref,
      rootSelector: options.rootSelector || this.defualtSelectorForBootstrap,
    };
    from(this.scriptService.loadScript(ScriptNames.PplLegacyVendor))
      .pipe(
        switchMap(() => from(this.scriptService.loadScript(ScriptNames.PplLegacyUtils))),
        switchMap(() => from(this.scriptService.loadScript(ScriptNames.PplLegacyApp))),
        switchMap(() => from(this.mqInfoService.getMqInfo())),
        combineLatestWith(this.workflowPermissionService.getWorkflowPermissions(options.workflowId as string)),
        switchMap(([mqInfo, workflowPermissions]) =>
          from(
            window.initLegacyPipeline({
              options: optionsWithSelector,
              config: this.pipelineConfigService.getConfig(),
              workflowPermissions,
              mqInfo,
              services: {
                selfaiAuthService: this.selfaiAuthService.getProvider(),
                workflowStateService: this.workflowStateService,
                editoreStateService: this.editoreStateService,
                datasourcePermissionService: this.datasourcePermissionService,
                avaliableDatasources: this.avaliableDatasources,
              },
            }),
          ),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe((app) => {
        this.app = app;
        this.angularBridge.setHookService(this.app.get('AngularBridgeService'));

        if (onBootstrapEnd) {
          onBootstrapEnd();
        }
      });
  }

  public destroyPipeline() {
    if (this.app) {
      this.angularBridge.clearHookService();
      this.app.get('$rootScope').$destroy();
    }
  }
}
