import {
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import * as _ from 'lodash';

import {
  CustomField,
  Format,
  Pivot,
  PivotField,
  UIChartFormat,
  UIOption,
  createPivot,
} from '@selfai-platform/bi-domain';

import { BaseOptionComponent } from './base-option.component';
import { FormatItemComponent } from './format/format-item.component';

@Component({
  selector: 'format-option',
  templateUrl: './format-option.component.html',
})
export class FormatOptionComponent extends BaseOptionComponent implements OnInit, OnDestroy {
  @ViewChild('commonFormat', {})
  private commonFormatComp: FormatItemComponent;

  @Output('changeEach')
  public changeEachEvent: EventEmitter<any> = new EventEmitter();

  @Output('changeCommon')
  public changeCommonEvent: EventEmitter<any> = new EventEmitter();

  public fieldList: PivotField[] = [];

  public pivot: Pivot;

  public format: Format;

  @Input('pivot')
  set setPivot(pivot: Pivot) {
    if (pivot === undefined) {
      this.pivot = createPivot();
    } else {
      this.pivot = pivot;
    }

    const fieldList: PivotField[] = _.cloneDeep(_.concat(this.pivot.columns, this.pivot.rows, this.pivot.aggregations));
    for (let num: number = fieldList.length - 1; num >= 0; num--) {
      if ('measure' != fieldList[num].type.toLowerCase()) {
        fieldList.splice(num, 1);
      }
    }

    for (const afterField of fieldList) {
      let isBeforeFormat = false;
      for (const beforeField of this.fieldList) {
        if (afterField.name == beforeField.name && afterField.aggregationType == beforeField.aggregationType) {
          afterField.format == beforeField.format;
          isBeforeFormat = true;
          break;
        }
      }
      if (!isBeforeFormat && !afterField.format) {
        afterField.format = this.format;

        _.concat(this.pivot.columns, this.pivot.rows, this.pivot.aggregations).forEach((field) => {
          if (
            field.type == 'measure' &&
            field.name == afterField.name &&
            field.aggregationType == afterField.aggregationType
          ) {
            field.format = this.format;
          }
        });

        this.changeEachEvent.emit(this.pivot);
      }
    }

    this.fieldList = fieldList;

    if (this.format) {
      this.apply();
    }
  }

  set setFormat(format: Format) {
    if (!format) {
      if (this.uiOption && this.uiOption.valueFormat) {
        format = this.uiOption.valueFormat;
      } else {
        return;
      }
    }

    this.format = format;
  }

  @Input('uiOption')
  public set setUiOption(uiOption: UIOption) {
    this.uiOption = uiOption;

    this.setFormat = this.uiOption.valueFormat;
  }

  constructor(protected elementRef: ElementRef, protected injector: Injector) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public setFormatType(field: PivotField): void {
    this.format = field.format;

    this.onChange(this.format);
  }

  public onChange(target: Object): void {
    this.format = target as Format;

    _.concat(this.pivot.columns, this.pivot.rows, this.pivot.aggregations).forEach((field) => {
      if (field.type == 'measure') {
        field.format = this.format;
      }
    });

    this.changeEachEvent.emit(this.pivot);
    this.changeCommonEvent.emit(this.format);

    this.apply();
  }

  public getFieldNmae(field: CustomField): string {
    const name: string = field.alias ? field.alias : field.fieldAlias ? field.fieldAlias : field.name;

    if (!field.aggregated && field.aggregationType && (!field.alias || field.alias == field.name)) {
      return field.aggregationType + '(' + name + ')';
    } else {
      return name;
    }
  }

  protected apply(): void {
    const uiFormat: UIChartFormat = {
      isAll: true,
      type: this.format.type,
      sign: this.format.sign,
      decimal: this.format.decimal,
      useThousandsSep: this.format.useThousandsSep,
      customSymbol: this.format.customSymbol,
      abbr: this.format.abbr,
    };
    this.uiOption = <UIOption>_.extend({}, this.uiOption, { valueFormat: uiFormat });

    this.update();
  }
}
