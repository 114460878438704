import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { SelfaiAuthService } from './selfai-auth.service';

@Injectable({
  providedIn: 'root',
})
export class AppAuthGuard implements CanActivate {
  private authenticated: boolean;
  private roles: string[];

  constructor(private readonly router: Router, private readonly selfaiAuthService: SelfaiAuthService) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    try {
      this.authenticated = await this.selfaiAuthService.getProvider().isLoggedIn();
      this.roles = await this.selfaiAuthService.getProvider().getUserRoles(true);

      return await this.isAccessAllowed(route);
    } catch (error) {
      throw new Error('An error happened during access validation. Details:' + error);
    }
  }

  async isAccessAllowed(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    // Force the user to log in if currently unauthenticated.
    if (!this.authenticated) {
      await this.selfaiAuthService.getProvider().login({
        redirectUri: window.location.href,
      });
    }

    // Get the roles required from the route.
    const requiredRoles = route.data['roles'];

    let granted = false;

    // Allow the user to to proceed if no additional roles are required to access the route.
    if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
      granted = true;

      return granted;
    }

    // Allow the user to proceed if all the required roles are present.
    granted = requiredRoles.some((role) => this.roles.includes(role));

    // Routing user into permission denied view if don't have necessary roles.
    if (!granted) {
      await this.router.navigate(['access-denied']);
    }

    return granted;
  }
}
