import { Injectable } from '@angular/core';
import { TreeNodeCube, TreeNodeWorkflow } from '@selfai-platform/pipeline-common';
import { BehaviorSubject } from 'rxjs';
import { WorkflowDetailService } from '../../../wokflow';

@Injectable()
export class CubeListRenameCubeService {
  renamedNode$ = new BehaviorSubject<TreeNodeCube | null>(null);

  constructor(private readonly workflowDetailService: WorkflowDetailService) {}

  startRenameCube(node: TreeNodeCube): void {
    this.renamedNode$.next(node);
  }

  cancelRenameCube() {
    this.renamedNode$.next(null);
  }

  saveRenameCube(newName: string): void {
    const renamedNode = this.renamedNode$.value;
    if (renamedNode && newName.trim().length > 0) {
      renamedNode.label = newName;
      const parentTreeNodeWorkflow = renamedNode.parent?.parent as TreeNodeWorkflow | undefined;

      if (renamedNode.data?.node && parentTreeNodeWorkflow?.data) {
        this.workflowDetailService.updateCubeUiName({
          uiName: newName,
          id: renamedNode.data.node.id,
        });
        renamedNode.data.node.uiName = newName;
      }
    }
    this.renamedNode$.next(null);
  }
}
