import { Injectable } from '@angular/core';
import { filter, map, Observable } from 'rxjs';
import { KdBackendApiService } from '../../common';
import { DashboardDetailViewApiModel } from '../models';
import { DashboardApiService } from './dashboard-api.service';

@Injectable({ providedIn: 'root' })
export class MainDashboardApiService {
  constructor(
    private readonly kdBackendApiService: KdBackendApiService,
    private readonly dashboardApiService: DashboardApiService,
  ) {}

  setMainDashboard(dashboardId: string): Observable<void> {
    const url = this.kdBackendApiService.buildPath(`/dashboards/${dashboardId}/main`);

    return this.kdBackendApiService.put(url, null);
  }

  getMainDashboard(): Observable<DashboardDetailViewApiModel> {
    return this.dashboardApiService.getList({ page: 0, size: 1, projection: 'forDetailView', main: true }).pipe(
      map((response) => response._embedded.dashboards?.[0]),
      filter(Boolean),
    );
  }
}
