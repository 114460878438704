import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { DialogModule } from 'primeng/dialog';
import { CodeEditorComponent } from './code-editor.component';

@NgModule({
  imports: [CommonModule, CommonModule, FormsModule, DialogModule, MonacoEditorModule],
  declarations: [CodeEditorComponent],
  exports: [CodeEditorComponent],
})
export class CodeEditorComponentModule {}
