export function jsonToValues(obj: any) {
  let values: string[] = [];
  for (const key in obj) {
    if (typeof obj[key] === 'object' && !Array.isArray(obj[key]) && obj[key] !== null) {
      const subValues = jsonToValues(obj[key]);
      values = [...values, ...subValues];
    } else if (Array.isArray(obj[key])) {
      for (let i = 0; i < obj[key].length; i++) {
        const subValues = jsonToValues(obj[key][i]);
        values = [...values, ...subValues];
      }
    } else {
      if (obj[key] && obj[key].toString) {
        values.push(obj[key].toString());
      }
    }
  }

  return values;
}
